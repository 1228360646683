import axios from "axios";
import { AxiosResponse } from "axios";
import * as _ from "lodash";

import * as apiTypes from "../../shared/apiTypes";
import { TrackingEvent } from "../../shared/types";

// axios.interceptors.response.use(
//   (config) => config,
//   (error) => {
//     switch (error?.response?.status) {
//       case 401:
//       case 403:
//         if (error?.response?.data?.reason === "googlePhotos") {
//           window.location.pathname = "/allowGooglePhotos";
//           return;
//         }
//         return error;

//       default:
//         return error;
//     }
//   }
// );

// === Start User ===

export const getUser = async () => {
  return (await axios.get("/api/user")).data as apiTypes.UserResponse;
};

export const updateUser = async (update: { displayName: string }) => {
  await axios.post<{}, {}, apiTypes.UpdateUserRequest>(
    "/api/user/update",
    update
  );
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  await axios.post<{}, {}, apiTypes.UpdatePasswordRequest>(
    "/api/user/updatePassword",
    {
      oldPassword,
      newPassword,
    }
  );
};

// === End User ===

export const addBetaTester = async (email: string) =>
  (await axios.post("/api/betaTester", { email })).data;

export const logout = async () => await axios.post("/auth/logout");

export const track = async (event: TrackingEvent) =>
  await axios.post<{}, {}, apiTypes.TrackRequest>("/api/track", { event });

export const deleteAllPhotoData = async () =>
  await axios.post<{}, {}, apiTypes.TrackRequest>("/api/deleteAllPhotoData");

// === Start High scores ===

export const listHighScores = async (groupId: number, day: number) =>
  (await axios.get(`/api/listHighScores/${groupId}/${day}`))
    .data as apiTypes.ListHighScoresResponse;

export const postHighScore = async (data: apiTypes.PostHighScore) =>
  await axios.post<{}, {}, apiTypes.PostHighScore>(`/api/highScore`, data);

export const getHighScores = async (groupUniqueName: string, day: number) =>
  (await axios.get(`/api/highScores/${groupUniqueName}/${day}`))
    .data as apiTypes.GetHighScoresResponse;

// === End High scores ===

export const postImages = async (
  groupId: number,
  userName: string,
  imageFiles: File[]
) => {
  const formData = new FormData();
  formData.append("groupId", groupId.toString());
  formData.append("userName", userName.toString());
  for (const imageFile of imageFiles) {
    formData.append("images", imageFile, imageFile.name);
  }
  await axios.post(`/api/images`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getSession = async (groupId: number, day: number) =>
  (await axios.get(`/api/session/${groupId}/${day}`))
    .data as apiTypes.GetSession;

export const upcomingPhotoCount = async (
  groupId: number
): Promise<apiTypes.GetUpcomingPhotoCount> =>
  (
    await axios.get<apiTypes.GetUpcomingPhotoCount>(
      `/api/upcomingPhotoCount/${groupId}`
    )
  ).data;

export const upcomingPhotos = async (
  groupId: number
): Promise<apiTypes.GetUpcomingPhotosResponse> =>
  (
    await axios.get<apiTypes.GetUpcomingPhotosResponse>(
      `/api/upcomingPhotos/${groupId}`
    )
  ).data;

type LoginOptions =
  | {
      type: "username";
      group: string;
      username: string;
      password: string;
    }
  | {
      type: "email";
      email: string;
      password: string;
    };

/** Returns true for success or false for failure. */
export const login = async (options: LoginOptions): Promise<boolean> => {
  let result;
  try {
    result = await axios.post("/auth/login", {
      username:
        options.type === "username"
          ? `username:${options.group}:${options.username}`
          : `email:${options.email}`,
      password: options.password,
    });
  } catch (error) {
    console.log("caught error: ", error);
    return false;
  }

  return result.status === 200;
};

export const signUp = async (
  email: string,
  displayName: string,
  password: string,
  groupInviteCode?: string
): Promise<apiTypes.PostUserResponse> => {
  return (
    await axios.post<apiTypes.PostUserResponse>("/auth/user", {
      email,
      displayName,
      password,
      groupInviteCode,
    })
  ).data;
};

export const postGroup = async (
  name: string
): Promise<apiTypes.PostGroupResponse> => {
  return (
    await axios.post<apiTypes.PostGroupResponse>("/api/group", {
      name,
    })
  ).data;
};

export const getGroups = async (
  day: number
): Promise<apiTypes.GetGroupsResponse> =>
  (await axios.get<apiTypes.GetGroupsResponse>(`/api/groups/${day}`)).data;

export const getGroup = async (
  groupUniqueName: string,
  upToDay: number
): Promise<apiTypes.GetGroupResponse> =>
  (
    await axios.get<apiTypes.GetGroupResponse>(
      `/api/group/${groupUniqueName}/upToDay/${upToDay}`
    )
  ).data;

export const getGroupInviteFromGroupId = async (
  groupId: number
): Promise<apiTypes.GetGroupInviteResponse> =>
  (
    await axios.get<apiTypes.GetGroupInviteResponse>(
      `/api/group/${groupId}/invite`
    )
  ).data;

export const deleteGroupMember = async (groupId: number, userId: number) =>
  await axios.post<{}, {}, apiTypes.DeleteMemberRequest>(
    `/api/group/${groupId}/removeMember`,
    {
      // XXX Group ID here is redundant since it's also in the URL path
      groupId,
      userId,
    }
  );

export const updateGroup = async (
  groupId: number,
  displayName: string,
  uniqueName: string
) =>
  await axios.post<{}, {}, apiTypes.UpdateGroupRequest>(
    `/api/group/${groupId}/update`,
    {
      displayName,
      uniqueName,
    }
  );

// === Start groupInvite ===

export const postGroupInvite = async (groupId: number) => {
  return (
    await axios.post<
      {},
      AxiosResponse<apiTypes.PostGroupInviteResponse>,
      apiTypes.PostGroupInviteRequest
    >(`/api/group/${groupId}/invite`)
  ).data;
};

export const getGroupInvite = async (code: string) => {
  return (
    await axios.get<apiTypes.GetGroupInviteFromCodeResponse>(
      `/api/groupInvite/${code}`
    )
  ).data;
};

export const updateGroupInvite = async (code: string, totalInvites: number) =>
  await axios.post<{}, {}, apiTypes.UpdateGroupInviteRequest>(
    `/api/groupInvite/${code}/update`,
    {
      totalInvites,
    }
  );

export const deleteGroupInvite = async (code: string) =>
  await axios.post<{}, {}, apiTypes.DeleteGroupInviteRequest>(
    `/api/groupInvite/${code}/delete`
  );

export const useGroupInvite = async (code: string) =>
  await axios.post<{}, {}, {}>(`/api/groupInvite/${code}/use`);

// === End groupInvite ===
