import * as _ from "lodash";
import { Component, createRef, h, RefObject } from "preact";

import * as api from "../api";
import { Group, User } from "../../../shared/types";
import ImageCropper from "../components/ImageCropper";
import MenuPageContainer from "../components/MenuPageContainer";
import WhiteCard from "../components/WhiteCard";
import style from "../styles/UploadPage.module.scss";
import * as utils from "../utils";

interface State {
  photoDataUrls: string[];
  upcomingPhotoCounts:
    | {
        days: number;
        photos: number;
      }
    | undefined;
  uploading: boolean;
  group?: Group;
}

interface Props {
  user?: User;
  group?: string;
  fromDay?: string;
}

class UploadPage extends Component<Props, State> {
  imageCropperRefs: RefObject<ImageCropper>[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      photoDataUrls: [],
      upcomingPhotoCounts: undefined,
      uploading: false,
    };

    this.fetchGroup();
  }

  async fetchGroup() {
    if (!this.props.group) {
      throw Error("No group specified");
    }

    const group = await api.getGroup(
      this.props.group,
      utils.daysSinceStartDate()
    );

    this.setState({ ...this.state, group }, () => {
      this.fetchUpcomingPhotosCounts();
    });
  }

  fromDay() {
    return this.props.fromDay ? parseInt(this.props.fromDay, 10) : undefined;
  }

  async fetchUpcomingPhotosCounts() {
    const { group } = this.state;
    if (!group) {
      throw Error("No group specified");
    }

    const upcomingPhotoCounts = await api.upcomingPhotoCount(group.id);
    this.setState({ ...this.state, upcomingPhotoCounts, uploading: false });
  }

  async dropHandler(event: h.JSX.TargetedDragEvent<HTMLDivElement>) {
    // Prevent files from being opened.
    event.preventDefault();

    if (!event.dataTransfer) {
      throw Error("Drop failed, no data transfer");
    }

    const files: any[] = [];
    if (event.dataTransfer.items) {
      const numItems = event.dataTransfer.items.length;

      for (let i = 0; i < numItems; i++) {
        console.log("i: ", i);
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === "file") {
          const file = event.dataTransfer.items[i].getAsFile();
          if (!file) {
            throw Error("Not a file");
          }
          files.push(file);
        } else {
          console.log(`${i} is of kind: `, event.dataTransfer.items[i].kind);
          // XXX Deal with this case??
        }
      }
    }

    await this.addFiles(files);
  }

  async addFiles(files: File[]) {
    const newPhotoDataUrls: string[] = [];
    for (const file of files) {
      const dataUrl: string = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.readAsDataURL(file);
      });
      newPhotoDataUrls.push(dataUrl);
    }

    const photoDataUrls = [...this.state.photoDataUrls, ...newPhotoDataUrls];

    // Add enough imageCropperRefs to accommodate the updated photoDataUrls.
    while (this.imageCropperRefs.length < photoDataUrls.length) {
      this.imageCropperRefs.push(createRef<ImageCropper>());
    }

    this.setState({
      ...this.state,
      photoDataUrls,
    });
  }

  async handleFileChange(event: h.JSX.TargetedEvent<HTMLInputElement, Event>) {
    const files = (event.target as any).files;
    await this.addFiles(files);
  }

  async uploadPhotos() {
    if (!this.state.group) {
      throw Error("No group specified");
    }

    const imageFiles: File[] = [];

    this.setState({ ...this.state, uploading: true });

    for (const [index, _photo] of this.state.photoDataUrls.entries()) {
      const imageCropper = this.imageCropperRefs[index].current;
      if (imageCropper) {
        const dataUrl = imageCropper.getJPEGDataUrl();
        const arrayBuffer = await (await fetch(dataUrl)).arrayBuffer();
        imageFiles.push(
          new File([arrayBuffer], `photo-${index}.jpg`, { type: "image/jpeg" })
        );
      }
    }

    for (const chunk of _.chunk(imageFiles, 2)) {
      await api.postImages(
        this.state.group.id,
        localStorage["name"] ?? "Unknown",
        chunk
      );
    }

    // Remove photos from state
    this.setState({ ...this.state, photoDataUrls: [] }, () => {
      this.fetchUpcomingPhotosCounts();
    });

    // XXX TODO:
    // - Add message telling user that they will see their photos appear in JigglePix
    // over the coming days.
    // - Reset input file count to 0
    // - Tell user if some of the photos weren't added because they were already there.
  }

  deletePhoto(index: number) {
    this.setState({
      ...this.state,
      photoDataUrls: [
        ...this.state.photoDataUrls.slice(0, index),
        ...this.state.photoDataUrls.slice(index + 1),
      ],
    });
  }

  renderFooter(): JSX.Element {
    const { photoDataUrls, uploading } = this.state;

    return uploading ? (
      <h2>Uploading...</h2>
    ) : photoDataUrls.length > 0 ? (
      <button
        class={style.button}
        onClick={() => this.uploadPhotos()}
        disabled={uploading}
      >
        UPLOAD {photoDataUrls.length} PHOTOS
      </button>
    ) : (
      <form>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={(event) => this.handleFileChange(event)}
          id="choose-files-button"
          hidden
        />
        <label for="choose-files-button" class={style.button}>
          CHOOSE PHOTOS
        </label>
      </form>
    );
  }

  render() {
    const { group, upcomingPhotoCounts, photoDataUrls, uploading } = this.state;
    const { user, group: groupUniqueName } = this.props;

    if (!groupUniqueName || !group) {
      return <div>Loading...</div>;
    }

    return (
      <div
        onDrop={(event) => this.dropHandler(event)}
        onDragOver={(event) =>
          // This is necessary for onDrop to work
          event.preventDefault()
        }
      >
        <MenuPageContainer
          user={user}
          group={groupUniqueName}
          background="orange"
          pageTitle="Add Photos"
          backLink={{ type: "group", groupUniqueName, day: this.fromDay() }}
          footerContent={this.renderFooter()}
          mode="scroll"
        >
          {photoDataUrls.length === 0 && upcomingPhotoCounts ? (
            <WhiteCard>
              <div class={style.card}>
                <strong>{upcomingPhotoCounts.photos}</strong> photo
                {upcomingPhotoCounts.photos !== 1 ? "s" : null} left
                <br />
                <strong>{upcomingPhotoCounts.days}</strong> more day
                {upcomingPhotoCounts.days !== 1 ? "s" : null}
                <br />
                <br />
                Add more photos to keep the game going!
              </div>
            </WhiteCard>
          ) : (
            <div class={style.imagePreviewContainer}>
              {photoDataUrls.map((url, index) => (
                <div class={style.imagePreview} key={url}>
                  <ImageCropper
                    key={url}
                    imageSize={1500}
                    canvasSize={150}
                    class={style.imageCropper}
                    photoDataUrl={url}
                    ref={this.imageCropperRefs[index]}
                  />
                  <div
                    class={style.deleteButton}
                    onClick={() => this.deletePhoto(index)}
                  >
                    <img class={style.x} src="/assets/x.svg" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </MenuPageContainer>
      </div>
    );
  }
}

export default UploadPage;
