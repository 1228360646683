import { Component, h } from "preact";
import { route } from "preact-router";

import { User } from "../../../shared/types";

import * as api from "../api";
import AuthForm from "../components/AuthForm";
import MenuPageContainer from "../components/MenuPageContainer";
import { FooterButtonModel } from "../components/FooterButtons";
import FooterButton from "../components/FooterButtons";
import style from "../styles/LandingPage.module.scss";
import * as utils from "../utils";
import GroupSessionItem from "../components/GroupSessionItem";
import AddGroupButton from "../components/AddGroupButton";
import { GetGroupsResponse } from "../../../shared/apiTypes";
import WhiteCard from "../components/WhiteCard";

interface Props {
  loginMode: boolean;
  user?: User;
  group?: string;
  loggedIn: () => void;
  loggedOut: () => void;
}

interface State {
  groups?: GetGroupsResponse["groups"];
}

const inviteOnly = false;

class LandingPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    this.fetchGroups();
  }

  async fetchGroups() {
    const { groups } = await api.getGroups(utils.daysSinceStartDate());
    this.setState({ ...this.state, groups });
  }

  footerButtons(): FooterButtonModel[] | undefined {
    if (this.props.user !== undefined || inviteOnly) {
      return undefined;
    }

    return [
      {
        name: "Start Now",
        action: () => route(`/demo`),
        type: "primary",
      },
      {
        name: "Login",
        action: () => route(`/login`),
        type: "secondary",
      },
    ];
  }

  render() {
    const { user, group, loggedIn } = this.props;
    const groups = this.state.groups;

    return (
      <MenuPageContainer
        user={user}
        background="white"
        backLink={{ type: "none" }}
        mode="scroll"
      >
        {user ? (
          <div>
            <p class={style.blurb}>
              A daily photo puzzle game to play with friends and family
            </p>
            <div class={style.groups}>
              {groups === undefined ? (
                "Loading..."
              ) : (
                <div>
                  {groups.map((group, index) => (
                    <GroupSessionItem
                      group={group}
                      coverPhotoUrl={group.coverPhotoUrl}
                      day={utils.daysSinceStartDate()}
                      groupIndex={index}
                      mode="open-group"
                    />
                  ))}
                </div>
              )}
              <AddGroupButton user={user} />
            </div>
          </div>
        ) : inviteOnly ? (
          <WhiteCard narrow={true}>
            <h2>Login{group ? " to group" : " to JigglePix"}</h2>
            <AuthForm key="login" mode="login" loggedIn={loggedIn} />
            <h2>Don't have an account?</h2>
            <p>
              JigglePix is invite only for the time being, please ask a group
              admin to invite you, or reach out to me at{" "}
              <a href="mailto:steveridout@gmail.com">steveridout@gmail.com</a>.
            </p>
          </WhiteCard>
        ) : (
          <div class={style.contentContainer}>
            <div class={style.content}>
              <img src="/assets/heroImage.jpg" />
            </div>
          </div>
        )}
        <FooterButton buttons={this.footerButtons()} mode="full-screen" />
      </MenuPageContainer>
    );
  }
}

export default LandingPage;
