export interface SessionProperties {
  photo_set_index: number;
  total_photo_sets: number;
  season_index: number;
  already_completed: boolean;
}

export interface HomeProperties {
  total_stars: number;
  unlocked_seasons: number;
  total_seasons: number;
}

type TrackingEvent =
  | {
      event: "show_season";
      properties: HomeProperties & { season_year: number; season_name: string };
    }
  | {
      event: "show_season_list";
      properties: HomeProperties;
    }
  | {
      event: "puzzleSessionStart";
      properties: {}; // SessionProperties & HomeProperties;
    }
  | {
      event: "puzzle_session_end";
      properties: {}; // SessionProperties & HomeProperties;
    }
  | {
      event: "puzzleSessionQuit";
      properties: {}; // SessionProperties & HomeProperties;
    };

export const track = ({ event, properties }: TrackingEvent) => {
  console.log(event, properties);
};
