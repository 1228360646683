import * as _ from "lodash";
import { Component, h } from "preact";

import style from "../styles/TextInput.module.scss";
import * as utils from "../utils";

interface Props {
  fullWidth?: boolean;
  initialValue: string;
  saveValue: (value: string) => Promise<void>;
  maxLength?: number;
  placeholderText: string;
}

interface State {
  value: string;
  updating: boolean;
  numberTimesHittingLengthLimit: number;
}

class TextInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.initialValue,
      updating: false,
      numberTimesHittingLengthLimit: 0,
    };
  }

  render() {
    const { placeholderText, maxLength } = this.props;
    const { value } = this.state;

    return (
      <div
        class={utils.cn([
          style.textInput,
          this.state.numberTimesHittingLengthLimit > 0
            ? style.error
            : undefined,
        ])}
      >
        <input
          class={
            this.state.numberTimesHittingLengthLimit > 0
              ? this.state.numberTimesHittingLengthLimit % 2 === 0
                ? style.error1
                : style.error2
              : ""
          }
          type="text"
          value={value}
          placeholder={placeholderText}
          onInput={(event) => {
            const value = event.currentTarget.value;
            const incrementLengthLimit = maxLength && value.length > maxLength;

            this.setState(
              {
                ...this.state,
                value: value.substring(0, maxLength || undefined),
                updating: true,
                numberTimesHittingLengthLimit: incrementLengthLimit
                  ? this.state.numberTimesHittingLengthLimit + 1
                  : this.state.numberTimesHittingLengthLimit,
              },
              () => {
                this.saveDebounced();
              }
            );
          }}
        />{" "}
        <span
          class={utils.cn([
            style.savingNotification,
            this.state.updating ? style.active : undefined,
          ])}
        >
          {" "}
          (saving...)
        </span>
      </div>
    );
  }

  saveDebounced = _.debounce(async () => {
    const value = this.state.value;
    await this.props.saveValue(value);

    // If value hasn't changed since we started updating, set updating to false
    if (value === this.state.value) {
      this.setState({ ...this.state, updating: false });
    }
  }, 400);
}

export default TextInput;
