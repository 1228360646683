import { Component, h } from "preact";
import { Router } from "preact-router";

import { User } from "../../../shared/types";

import Redirect from "./Redirect";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import UploadPage from "../pages/UploadPage";
import NotFoundPage from "../pages/NotFound";
import PuzzlePage from "../pages/PuzzlePage";
import LandingPage from "../pages/LandingPage";
import ReviewUpcomingPhotos from "../pages/ReviewUpcomingPhotos";
import EditProfilePage from "../pages/EditProfilePage";
import EditGroupPage from "../pages/EditGroupPage";
import GroupPage from "../pages/GroupPage";
import CreateGroupPage from "../pages/CreateGroupPage";
import InvitePage from "../pages/InvitePage";
import ScoresPage from "../pages/ScoresPage";
import LoginPage from "../pages/LoginPage";
import SignUpPage from "../pages/SignUpPage";
import EndOfDemoPage from "../pages/EndOfDemoPage";
import * as api from "../api";

interface Props {}

interface State {
  user: User | undefined;
  loading: boolean;
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      user: undefined,
    };

    this.fetchUser();
  }

  async fetchUser() {
    let user: User | undefined;

    try {
      user = (await api.getUser()).user;
    } catch (error) {
      user = undefined;
    }

    this.setState({
      ...this.state,
      user,
      loading: false,
    });
  }

  loggedOut() {
    this.setState({ ...this.state, user: undefined });
  }

  render() {
    const { user, loading } = this.state;

    console.log("App render: ", user, loading);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (user === undefined) {
      return (
        <Router>
          <LandingPage
            path="/"
            loggedIn={() => this.fetchUser()}
            loggedOut={() => this.loggedOut()}
            loginMode={false}
          />
          <LoginPage path="/login" />
          <SignUpPage path="/signUp" />
          <LandingPage
            path="/j/:group"
            loggedIn={() => this.fetchUser()}
            loggedOut={() => this.loggedOut()}
            loginMode={true}
          />
          <PuzzlePage path="/demo" demoMode={true} />
          <EndOfDemoPage path="/demoEnd" />
          <InvitePage
            path="/i/:inviteCode"
            // loggedIn={() => this.fetchUser()}
            // loggedOut={() => this.loggedOut()}
          />
          <Redirect path="/ridout" to="/j/ridout" />
          {/* XXX Consider redirecting other /j/:group/* routes to /j/:group */}
        </Router>
      );
    }

    return (
      <div id="preact_root">
        <Router>
          <Redirect path="/reload" to="/" />
          <Redirect path="/login" to="/" />
          <Redirect path="/signUp" to="/" />
          <Redirect path="/ridout" to="/j/ridout" />
          <LandingPage
            path="/"
            user={user}
            loggedIn={() => this.fetchUser()}
            loggedOut={() => this.loggedOut()}
            loginMode={false}
          />
          <GroupPage path="/j/:groupUniqueName" user={user} />
          <PuzzlePage path="/j/:group/puzzle" user={user} />
          <ScoresPage path="/j/:group/scores/:day" user={user} />
          <UploadPage path="/j/:group/addPhotos" user={user} />
          <ReviewUpcomingPhotos path="/j/:group/reviewUpcomingPhotos" />
          <InvitePage path="/i/:inviteCode" user={user} />
          <PuzzlePage path="/demo" demoMode={true} />
          <EditProfilePage
            path="/editProfile"
            user={user}
            reloadUser={() => this.fetchUser()}
          />
          <EditProfilePage
            path="/j/:group/editProfile"
            user={user}
            reloadUser={() => this.fetchUser()}
          />
          <ChangePasswordPage
            path="/changePassword"
            user={user}
            reloadUser={() => this.fetchUser()}
          />
          <ChangePasswordPage
            path="/j/:group/changePassword"
            user={user}
            reloadUser={() => this.fetchUser()}
          />
          <EditGroupPage path="/j/:group/admin" user={user} />
          <CreateGroupPage path="/addGroup" user={user} />
          <NotFoundPage default />
        </Router>
      </div>
    );
  }
}

export default App;
