import { FunctionalComponent, h } from "preact";

import { User } from "../../../shared/types";

import * as utils from "../utils";
import style from "../styles/GroupSessionItem.module.scss";
import { route } from "preact-router";

interface Props {
  user: User;
}

const restrictGroupCreation = false;

const AddGroupButton: FunctionalComponent<Props> = ({ user }) => (
  <div>
    <div class={style.groupSessionItem}>
      <a
        class={utils.cn([style.groupButton, style["color-3"]])}
        onClick={() => {
          console.log("Click add group");
          if (
            restrictGroupCreation &&
            window.location.hostname !== "localhost" &&
            user.id !== 1
          ) {
            alert(
              "Please email me at steveridout@gmail.com " +
                "and I'll set up a new group for you!"
            );
            return;
          }

          route("/addGroup");
        }}
      >
        <div class={style.groupName}>YOUR NEW GROUP</div>
        <div class={utils.cn([style.coverPhoto, style.questionMark])}>?</div>
        <div class={style.startNow}>ADD GROUP</div>
      </a>
    </div>
  </div>
);

export default AddGroupButton;
