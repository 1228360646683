import { Component } from "preact";
import { route } from "preact-router";

export default class Redirect extends Component<{ to: string }, {}> {
  componentWillMount() {
    // XXX setTimeout is needed, otherwise this doesn't work.
    setTimeout(() => {
      console.log("redirecting to ", this.props.to);
      route(this.props.to, false);
    }, 0);
  }

  render() {
    return null;
  }
}
