import { Component, h } from "preact";

import * as api from "../api";
import style from "../styles/AuthForm.module.scss";
import * as utils from "../utils";

interface Props {
  mode: "signUp" | "login";
  loggedIn: () => void;
  initialGroupName?: string;
  inviteCode?: string;
}

interface State {
  fields: {
    email: string;
    password: string;
    name: string;
  };
  errorMessage?: {
    type: string;
    message: string;
  };
  awaitingResponse: boolean;
}

class AuthForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    console.log("creating login form");

    this.state = {
      fields: {
        email: "",
        password: "",
        name: "",
      },
      awaitingResponse: false,
    };
  }

  formRow(fieldName: keyof State["fields"]) {
    const value = this.state.fields[fieldName];
    const errorMessage =
      this.state.errorMessage?.type === fieldName
        ? this.state.errorMessage.message
        : undefined;

    const nameToDisplayName: Record<keyof State["fields"], string> = {
      email: "Email",
      password: "Password",
      name: "First Name",
    };

    return (
      <div>
        {errorMessage ? (
          <div class={style.errorMessage}>{errorMessage}</div>
        ) : null}
        <input
          class={utils.cn([
            style.textInput,
            errorMessage ? style.error : undefined,
          ])}
          id={fieldName}
          type={
            fieldName === "email"
              ? "email"
              : fieldName === "password"
              ? "password"
              : "text"
          }
          placeholder={nameToDisplayName[fieldName]}
          onInput={(event) => {
            this.setState({
              ...this.state,
              fields: {
                ...this.state.fields,
                [fieldName]: event.currentTarget.value,
              },
              errorMessage: undefined,
            });
          }}
          onKeyDown={(event) => {
            console.log("key code: ", event.code);
            if (event.code === "Enter") {
              console.log("pressed enter");
              this.loginOrSignUp();
            }
          }}
          value={value}
          autoFocus={
            errorMessage !== undefined ||
            (this.props.mode === "signUp" && fieldName === "name") ||
            (this.props.mode === "login" && fieldName === "email")
          }
        />
      </div>
    );
  }

  isValidAndPopulateErrorMessage(): boolean {
    const { email, password, name } = this.state.fields;

    if (this.props.mode === "signUp" && name.trim().length < 1) {
      this.setState({
        ...this.state,
        errorMessage: {
          type: "name",
          message: `Name required`,
        },
      });
      return false;
    }

    if (!/^(.+)@(.+)$/.test(email.trim())) {
      this.setState({
        ...this.state,
        errorMessage: {
          type: "email",
          message: "Email not valid",
        },
      });
      return false;
    }

    const MIN_PASSWORD_CHARACTERS = 6;
    if (password.length < MIN_PASSWORD_CHARACTERS) {
      this.setState({
        ...this.state,
        errorMessage: {
          type: "password",
          message: `Password must contain at least ${MIN_PASSWORD_CHARACTERS} characters`,
        },
      });
      return false;
    }

    this.setState({ ...this.state, errorMessage: undefined });
    return true;
  }

  async loginOrSignUp() {
    const { mode } = this.props;
    const { email, password, name } = this.state.fields;

    if (!this.isValidAndPopulateErrorMessage()) {
      return;
    }

    this.setState({
      ...this.state,
      awaitingResponse: true,
      errorMessage: undefined,
    });

    // For testing purposes, we'll wait for a second before actually sending the request
    // await sleep(1000);

    if (mode === "login") {
      const success = await api.login({
        type: "email",
        email: email.trim(),
        password,
      });

      if (success) {
        this.props.loggedIn();
      } else {
        this.setState({
          ...this.state,
          awaitingResponse: false,
          errorMessage: {
            type: "global",
            message: "Email or password not recognized",
          },
        });
      }
    } else {
      const signUpResponse = await api.signUp(
        email.trim(),
        name.trim(),
        password,
        this.props.inviteCode
      );
      switch (signUpResponse.type) {
        case "user-already-exists":
          this.setState({
            ...this.state,
            awaitingResponse: false,
            errorMessage: {
              type: "global",
              message:
                "An account already exists with this email address, did you mean to login instead?",
            },
          });
          return;

        case "user-created":
          this.props.loggedIn();
          return;
      }
    }
  }

  render() {
    const { mode } = this.props;
    const { email, password } = this.state.fields;
    const { awaitingResponse, errorMessage } = this.state;
    console.log({ email, password });

    return (
      <div class={style.loginForm}>
        {this.props.initialGroupName ? (
          <h2>Login to group "{this.props.initialGroupName}"</h2>
        ) : null}
        {/* {!this.props.initialGroupName ? this.formRow("group") : null} */}
        {mode === "signUp" ? this.formRow("name") : null}
        {this.formRow("email")}
        {this.formRow("password")}
        <div class={style.buttonContainer}>
          <button
            class={style.button}
            onClick={() => this.loginOrSignUp()}
            disabled={awaitingResponse}
          >
            {mode === "login"
              ? awaitingResponse
                ? "Logging in..."
                : "Login"
              : awaitingResponse
              ? "Signing up..."
              : "Sign up"}
          </button>
        </div>
        {/* {this.state.loginFailed ? (
          <div class={style.failedMessage}>
          </div>
          <p class={style.globalErrorMessage}>
            
            Email or password not recognized.
            {errorMessage.message}</p>
        ) : null} */}
        {errorMessage?.type === "global" ? (
          <p class={style.globalErrorMessage}>{errorMessage.message}</p>
        ) : null}
        {mode === "login" ? (
          <p>
            Forgot password? Email{" "}
            <a class={style.link} href="email:steveridout@gmail.com">
              steveridout@gmail.com
            </a>{" "}
            and ask to reset it.
          </p>
        ) : null}
      </div>
    );
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.mode !== this.props.mode) {
      this.setState({
        ...this.state,
        errorMessage: undefined,
      });
    }
  }
}

export default AuthForm;
