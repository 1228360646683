/** From: https://codesandbox.io/s/5kxok5on9l?file=/ElementContainer.js:0-869 */
import { h, Component } from "preact";

interface Props {
  // child: h.JSX.Element;
  child: any;
  class: string;
}

// Adapted from:
// https://www.npmjs.com/package/react-element-container
// Allows us to just use a regular DOM element as a child
// without having to deal with updates/reactive things
export default class ElementContainer extends Component<Props, {}> {
  shouldComponentUpdate(nextProps: Props) {
    return this.props.child !== nextProps.child;
  }

  componentDidUpdate(prevProps: Props) {
    this._updateChild(prevProps);
  }

  componentDidMount() {
    (this._updateChild as any)({});
  }

  _updateChild(prevProps: Props) {
    const wrap = this.base;
    const next = this.props.child;
    const prev = prevProps.child;

    if (next) {
      wrap?.appendChild(next);
    }

    if (prev && prev !== next && prev?.parentNode === wrap) {
      wrap?.removeChild(prev);
    }
  }

  render() {
    return <div {...this.props} />;
  }
}
