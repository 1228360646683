import { FunctionalComponent, h } from "preact";

import style from "../styles/invisiblePreFetchedPhotos.module.scss";

interface Props {
  photoUrls: string[];
}

/**
 * Render the photos to the DOM invisibly to force the browser to pre-fetch them so that when we
 * actually show them to the user they won't need to wait.
 */
const InvisiblePreFetchedPhotos: FunctionalComponent<Props> = ({
  photoUrls,
}) => {
  return (
    <div class={style.invisiblePreFetchedPhotos}>
      {photoUrls.map((photoUrl) => (
        <img src={photoUrl} />
      ))}
      <img />
    </div>
  );
};

export default InvisiblePreFetchedPhotos;
