import * as _ from "lodash";
import { Component, h } from "preact";

import { User } from "../../../shared/types";

import * as api from "../api";
import MenuPageContainer from "../components/MenuPageContainer";
import TextInput from "../components/TextInput";
import WhiteCard from "../components/WhiteCard";

interface State {
  displayName: string;
}

interface Props {
  user: User;
  group?: string;
  reloadUser?: () => void;
}

class EditProfilePage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      displayName: props.user.displayName,
    };
  }

  render() {
    const { user, group, reloadUser } = this.props;
    const { displayName } = this.state;

    return (
      <MenuPageContainer
        user={user}
        background={"green"}
        backLink={
          group ? { type: "group", groupUniqueName: group } : { type: "home" }
        }
        group={group}
        mode="scroll"
      >
        <WhiteCard>
          <h2>Edit Profile</h2>
          <div>
            Name:{" "}
            <TextInput
              initialValue={displayName}
              placeholderText="Type your name here"
              saveValue={async (value) => {
                console.log("saving value...", value);
                await api.updateUser({ displayName: value });
                reloadUser?.();
              }}
              maxLength={20}
            />
          </div>
        </WhiteCard>
      </MenuPageContainer>
    );
  }
}

export default EditProfilePage;
