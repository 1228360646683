import * as _ from "lodash";
import { Component, h } from "preact";

import * as api from "../api";
import MenuPageContainer from "../components/MenuPageContainer";
import { User } from "../../../shared/types";
import WhiteCard from "../components/WhiteCard";
import { FooterButtonModel } from "../components/FooterButtons";
import style from "../styles/CreateGroupPage.module.scss";
import { route } from "preact-router";

interface State {
  groupName: string;
}

interface Props {
  user: User;
}

class CreateGroupPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { groupName: "" };
  }

  footerButtons(): FooterButtonModel[] {
    return [
      {
        name: "Create Group",
        action: () => this.createGroup(),
        type: "primary",
      },
    ];
  }

  async createGroup() {
    const { group } = await api.postGroup(this.state.groupName);
    route(`/j/${group.uniqueName}`);
  }

  render() {
    const { user } = this.props;

    return (
      <MenuPageContainer
        user={user}
        background={"orange"}
        // XXX Should we display the group name here??
        //     Maybe we should if we add group-specific settings on this page.
        pageTitle="Add Group"
        backLink={{ type: "home" }}
        mode="scroll"
        footerButtons={this.footerButtons()}
      >
        <WhiteCard>
          <h2>What do you want to call your new group?</h2>
          <input
            type="text"
            class={style.input}
            placeholder="Type group name"
            autoFocus={true}
            onInput={(event) => {
              this.setState({ groupName: event.currentTarget.value });
            }}
            value={this.state.groupName}
          />
        </WhiteCard>
      </MenuPageContainer>
    );
  }
}

export default CreateGroupPage;
