import _ from "lodash";
import { Component, h } from "preact";

import * as apiTypes from "../../../shared/apiTypes";

import * as utils from "../utils";
import style from "../styles/TopBarMenu.module.scss";

interface Props {
  logout: () => void;
  group: apiTypes.GetGroupResponse;
}

interface State {
  dropDownVisible: boolean;
}

class TopBarMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { group } = this.props;

    return (
      <div class={style.topBarMenu}>
        {this.state.dropDownVisible ? (
          <span
            // class={utils.cn([style.nextButton, style.addPhotoButton])}
            onClick={() =>
              this.setState({
                ...this.state,
                dropDownVisible: false,
              })
            }
          >
            X
            <ul class={style.dropDown}>
              <li
                onClick={() => {
                  window.location.href = `/j/${group.uniqueName}/upload`;
                }}
              >
                UPLOAD PHOTOS
              </li>
              <li
                onClick={() => {
                  window.location.href = `/j/${group.uniqueName}/editProfile`;
                }}
              >
                EDIT PROFILE
              </li>
              {this.props.group.admin ? (
                <li
                  onClick={() => {
                    window.location.href = `/j/${group.uniqueName}/admin`;
                  }}
                >
                  MANAGE MEMBERS
                </li>
              ) : null}
              {/* <li
                onClick={() => {
                  window.location.href = "/ridout/createGroup";
                }}
              >
                ADD NEW GROUP
              </li> */}
              <li
                onClick={() => {
                  // this.props.logout();
                  window.location.href = "/";
                }}
              >
                SHOW ALL GROUPS
              </li>
              <li
                onClick={() => {
                  this.props.logout();
                }}
              >
                LOGOUT
              </li>
            </ul>
          </span>
        ) : (
          <span
            class={utils.cn([style.nextButton, style.addPhotoButton])}
            onClick={() =>
              this.setState({ ...this.state, dropDownVisible: true })
            }
          >
            ☰
          </span>
        )}
      </div>
    );
  }
}

export default TopBarMenu;
