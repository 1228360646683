// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DRaLGqTYlKK5Ut26fLN3YQ\\=\\={opacity:0;position:fixed;pointer-events:none}", "",{"version":3,"sources":["webpack://./src/styles/invisiblePreFetchedPhotos.module.scss"],"names":[],"mappings":"AAAA,4BACE,SAAA,CACA,cAAA,CACA,mBAAA","sourcesContent":[".invisiblePreFetchedPhotos {\n  opacity: 0;\n  position: fixed;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invisiblePreFetchedPhotos": "DRaLGqTYlKK5Ut26fLN3YQ=="
};
export default ___CSS_LOADER_EXPORT___;
