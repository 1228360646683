import { Photo } from "./types";

export const OLD_METHOD_STRING = "old_method";

export const secondsSinceEpoch = (date: Date) =>
  Math.floor(date.getTime() / 1000);

export const photoUrl = (groupId: number, fileHash: string) => {
  return `/photos/${groupId}/${fileHash}.jpg`;
};

export const capitalizeWord = (word: string) =>
  `${word[0].toUpperCase()}${word.substring(1)}`;

const emailPrefix = "email:";

export const usernameToEmail = (username: string): string | undefined => {
  if (username.startsWith(emailPrefix)) {
    return username.substring(emailPrefix.length);
  }
  return;
};

export const emailToUsername = (email: string): string =>
  `${emailPrefix}${email}`;

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
