import * as _ from "lodash";
import { Component, h } from "preact";
import ElementContainer from "./ElementContainer";

import style from "../styles/ImageCropper.module.scss";

interface State {
  cropOffset: number;
  mouseDownPosition: { x: number; y: number } | undefined;
}

interface Props {
  class: string;
  photoDataUrl: string;
  imageSize: number;
  canvasSize: number;
}

class ImageCropper extends Component<Props, State> {
  canvas: HTMLCanvasElement;
  image: HTMLImageElement;
  context: CanvasRenderingContext2D | undefined;
  rafId: number | undefined;
  padding = 0;

  /** This will be set to the width of the image. */
  imageWidth = 0;

  /** This will be set to the height of the image. */
  imageHeight = 0;

  constructor(props: Props) {
    super(props);
    this.canvas = document.createElement("canvas");
    this.canvas.style.width = `${props.canvasSize}px`;
    this.canvas.style.height = `${props.canvasSize}px`;
    this.canvas.style.borderRadius = "20px";

    this.canvas.addEventListener("mousedown", this.onMouseDown);

    this.image = document.createElement("img");
    this.state = {
      cropOffset: 0,
      mouseDownPosition: undefined,
    };
  }

  scrollDirection() {
    return this.imageWidth > this.imageHeight ? "horizontal" : "vertical";
  }

  scaleFactor() {
    return (this.props.imageSize + 2 * this.padding) / this.props.canvasSize;
  }

  onMouseDown = async (event: MouseEvent) => {
    this.setState({
      ...this.state,
      mouseDownPosition: {
        x:
          event.clientX -
          (this.scrollDirection() === "horizontal"
            ? this.state.cropOffset / this.scaleFactor()
            : 0),
        y:
          event.clientY -
          (this.scrollDirection() === "vertical"
            ? this.state.cropOffset / this.scaleFactor()
            : 0),
      },
    });

    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);
  };

  constrainCropOffset(newValue: number): number {
    const cropMaximum =
      this.scrollDirection() === "horizontal"
        ? (this.imageWidth - this.props.imageSize) / 2
        : (this.imageHeight - this.props.imageSize) / 2;

    return Math.min(cropMaximum, Math.max(-cropMaximum, newValue));
  }

  onMouseMove = async (event: MouseEvent) => {
    if (this.state.mouseDownPosition) {
      let cropOffset = 0;
      if (this.imageWidth > this.imageHeight) {
        cropOffset = this.constrainCropOffset(
          (event.clientX - this.state.mouseDownPosition.x) * this.scaleFactor()
        );
      } else {
        cropOffset = this.constrainCropOffset(
          (event.clientY - this.state.mouseDownPosition.y) * this.scaleFactor()
        );
      }
      this.setState({ ...this.state, cropOffset });
    }
  };

  onMouseUp = () => {
    this.setState({ ...this.state, mouseDownPosition: undefined });

    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
  };

  async setupCanvas() {
    this.image.src = this.props.photoDataUrl;
    await new Promise((resolve) => (this.image.onload = resolve));

    this.imageWidth = this.image.naturalWidth;
    this.imageHeight = this.image.naturalHeight;

    if (this.scrollDirection() === "horizontal") {
      this.imageWidth *= this.props.imageSize / this.imageHeight;
      this.imageHeight = this.props.imageSize;
    } else {
      this.imageHeight *= this.props.imageSize / this.imageWidth;
      this.imageWidth = this.props.imageSize;
    }

    this.canvas.width = this.props.imageSize + 2 * this.padding;
    this.canvas.height = this.props.imageSize + 2 * this.padding;
    this.context = this.canvas.getContext("2d") ?? undefined;
    this.rafId = window.requestAnimationFrame(this.draw);
  }

  componentDidMount() {
    this.setupCanvas();
  }

  render() {
    return <ElementContainer child={this.canvas} class={this.props.class} />;
  }

  draw = () => {
    const background = "#000";
    if (!this.context) {
      throw Error("context isn't defined");
    }
    this.context.fillStyle = background;
    console.log("this.context: ", this.context);
    this.context.fillRect(
      0,
      0,
      this.props.imageSize + 2 * this.padding,
      this.props.imageSize + 2 * this.padding
    );

    let xOffset = 0;
    let yOffset = 0;

    // Center the image:
    if (this.imageWidth > this.imageHeight) {
      xOffset =
        -(this.imageWidth - this.props.imageSize) / 2 + this.state.cropOffset;
    } else {
      yOffset =
        -(this.imageHeight - this.props.imageSize) / 2 + this.state.cropOffset;
    }

    this.context.drawImage(
      this.image,
      this.padding + xOffset,
      this.padding + yOffset,
      this.imageWidth,
      this.imageHeight
    );

    // Add crop masking rectangles:
    this.context.fillStyle = "rgba(0, 0, 0, 0.5)";
    this.context.fillRect(
      0,
      0,
      this.props.imageSize + 2 * this.padding,
      this.padding
    );
    this.context.fillRect(
      0,
      this.props.imageSize + this.padding,
      this.props.imageSize + 2 * this.padding,
      this.padding
    );
    this.context.fillRect(0, this.padding, this.padding, this.props.imageSize);
    this.context.fillRect(
      this.props.imageSize + this.padding,
      this.padding,
      this.padding,
      this.props.imageSize
    );

    // this.context.strokeStyle = "#fff";
    // this.context.strokeRect(
    //   this.padding,
    //   this.padding,
    //   this.imageSize,
    //   this.imageSize
    // );
  };

  componentDidUpdate(_prevProps: Props, prevState: State) {
    if (this.state.cropOffset !== prevState.cropOffset && this.context) {
      this.context = this.canvas.getContext("2d") ?? undefined;
      this.rafId = window.requestAnimationFrame(this.draw);
    }
  }

  getJPEGDataUrl(): string {
    return this.canvas.toDataURL("image/jpeg", 0.6);
  }
}

export default ImageCropper;
