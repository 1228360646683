import * as _ from "lodash";
import { Component, h } from "preact";

import TextInput from "../components/TextInput";
import MenuPageContainer from "../components/MenuPageContainer";
import { User } from "../../../shared/types";
import {
  GetGroupResponse,
  GetGroupInviteResponse,
} from "../../../shared/apiTypes";

import * as api from "../api";
import * as utils from "../utils";
import style from "../styles/EditGroupPage.module.scss";
import WhiteCard from "../components/WhiteCard";

interface State {
  group?: GetGroupResponse;
  updatingGroup: boolean;
  inviteResponse?: GetGroupInviteResponse;
  copiedToClipboard: boolean;
}

interface Props {
  user: User;
  group?: string;
  fromDay?: string;
}

class EditGroupPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { updatingGroup: false, copiedToClipboard: false };
    this.fetchGroup();
  }

  async fetchGroup() {
    const group = await api.getGroup(
      this.props.group!,
      utils.daysSinceStartDate()
    );
    this.setState({ ...this.state, group }, () => {
      this.fetchGroupInvite();
    });
  }

  async fetchGroupInvite() {
    const group = this.state.group;
    if (group === undefined) {
      throw Error("Unexpected state");
    }

    const inviteResponse = await api.getGroupInviteFromGroupId(group.id);
    this.setState({ ...this.state, inviteResponse });
  }

  saveGroupName = async (displayName: string) => {
    if (this.state.group === undefined) {
      throw Error("Unexpected state");
    }
    await api.updateGroup(
      this.state.group.id,
      displayName,
      this.state.group.uniqueName
    );

    this.setState({
      ...this.state,
      group: { ...this.state.group, displayName },
    });
  };

  fromDay() {
    return this.props.fromDay ? parseInt(this.props.fromDay, 10) : undefined;
  }

  async createInvite() {
    if (this.state.group === undefined) {
      throw Error("Unexpected state");
    }

    const inviteResponse = await api.postGroupInvite(this.state.group.id);
    console.log("invite response: ", inviteResponse);
    this.setState({ ...this.state, inviteResponse });
  }

  async promptAndDeleteInvite() {
    if (this.state.inviteResponse?.groupInvite === undefined) {
      throw Error("Unexpected state");
    }

    if (
      !confirm(
        "Are you sure you want to close this group to new members?\n\n" +
          "If you do this, the current invite link will no longer work. " +
          "(You can always create a new link in future though.)"
      )
    ) {
      return;
    }

    await api.deleteGroupInvite(this.state.inviteResponse.groupInvite.code);
    this.setState({ ...this.state, inviteResponse: {} });
  }

  inviteLink(includeProtocol: boolean) {
    return `${includeProtocol ? `${window.location.protocol}//` : ""}${
      window.location.host
    }/i/${this.state.inviteResponse?.groupInvite?.code}`;
  }

  render() {
    const { user } = this.props;
    const { group, inviteResponse } = this.state;

    if (group === undefined) {
      return <div>Loading...</div>;
    }

    return (
      <MenuPageContainer
        user={user}
        background={"orange"}
        // XXX Should we display the group name here??
        //     Maybe we should if we add group-specific settings on this page.
        pageTitle="Edit Group"
        backLink={{
          type: "group",
          groupUniqueName: group.uniqueName,
          day: this.fromDay(),
        }}
        group={group.uniqueName}
        mode="scroll"
      >
        <WhiteCard
          key={`key-${this.state.group ? "group" : ""}-${
            this.state.inviteResponse ? "invite" : ""
          }`}
        >
          <h2>Group Name</h2>
          <div>
            Group Name:{" "}
            <TextInput
              placeholderText="Type a group name here"
              initialValue={group?.displayName}
              saveValue={async (value) => {
                await this.saveGroupName(value);
              }}
              maxLength={25}
            />
          </div>
          <h2>Members</h2>
          <table class={style.membersTable}>
            <tr>
              <th>Name</th>
              <th>Type</th>
            </tr>
            {group?.members.map((member) => (
              <tr>
                <td>{member.name}</td>
                <td>{member.type}</td>
                <td>
                  {group.admin && member.userId !== user.id ? (
                    <button
                      onClick={async () => {
                        if (
                          !confirm(
                            `Are you sure you want to remove ${member.name} from this group?`
                          )
                        ) {
                          return;
                        }

                        await api.deleteGroupMember(group.id, member.userId);
                        await this.fetchGroup();
                      }}
                    >
                      Remove User
                    </button>
                  ) : null}
                </td>
              </tr>
            ))}
            <tr></tr>
          </table>
          <h2>Invite New Members</h2>
          {inviteResponse ? (
            <div>
              <label>
                Invite link active:{" "}
                <input
                  type="checkbox"
                  checked={inviteResponse.groupInvite !== undefined}
                  onClick={(event) => {
                    event.preventDefault();
                    console.log("checked: ", event.currentTarget.checked);
                    if (event.currentTarget.checked) {
                      this.createInvite();
                    } else {
                      this.promptAndDeleteInvite();
                    }
                  }}
                />
              </label>
              {inviteResponse.groupInvite ? (
                <div>
                  <p>
                    Share the following link with your friends or family to
                    invite them to join:
                  </p>
                  <p>
                    <a href={this.inviteLink(true)}>{this.inviteLink(false)}</a>{" "}
                    <button
                      class={style.copyInviteLinkButton}
                      onClick={() => {
                        navigator.clipboard.writeText(this.inviteLink(true));
                        this.setState(
                          {
                            ...this.state,
                            copiedToClipboard: true,
                          },
                          () => {
                            setTimeout(() => {
                              this.setState({
                                ...this.state,
                                copiedToClipboard: false,
                              });
                            }, 2000);
                          }
                        );
                      }}
                      disabled={this.state.copiedToClipboard}
                    >
                      {this.state.copiedToClipboard
                        ? "Copied"
                        : "Copy to clipboard"}
                    </button>
                    {/* {this.state.copiedToClipboard ? " (Copied)" : null} */}
                  </p>
                  <p>
                    The above link allows{" "}
                    {inviteResponse.groupInvite.totalInvites} people to join,
                    and has been used by{" "}
                    {inviteResponse.groupInvite.invitesUsed}{" "}
                    {inviteResponse.groupInvite.invitesUsed === 1
                      ? "person "
                      : "people "}
                    so far, so{" "}
                    {inviteResponse.groupInvite.totalInvites -
                      inviteResponse.groupInvite.invitesUsed}{" "}
                    more people can use it.
                  </p>
                </div>
              ) : (
                <p>Check the box above to generate a new invite link.</p>
              )}
            </div>
          ) : (
            <div>...</div>
          )}
        </WhiteCard>
      </MenuPageContainer>
    );
  }
}

export default EditGroupPage;
