import * as _ from "lodash";
import { Component, h } from "preact";
import { route } from "preact-router";

import { User, HighScore } from "../../../shared/types";
import { GetGroupResponse } from "../../../shared/apiTypes";

import * as api from "../api";
import MenuPageContainer from "../components/MenuPageContainer";
import { FooterButtonModel } from "../components/FooterButtons";
import style from "../styles/GroupPage.module.scss";
import * as utils from "../utils";
import { photoUrl } from "../../../shared/utils";
import GroupSessionItem from "../components/GroupSessionItem";

interface Props {
  user: User;
  // XXX This is actually mandatory, but it needs to be optional for the router.
  groupUniqueName?: string;
  // loggedIn: () => void;
  // loggedOut: () => void;
}

interface State {
  groupResponse?: GetGroupResponse;
}

const getHighScoreForUser = (
  highScores: HighScore[],
  userId: number
): (HighScore & { first: boolean }) | undefined => {
  let maxScore: HighScore | undefined;

  for (const highScore of highScores) {
    if (highScore.userId !== userId) {
      continue;
    }

    if (maxScore === undefined) {
      maxScore = highScore;
      continue;
    }

    if (highScore.perfectCount > maxScore.perfectCount) {
      maxScore = highScore;
      continue;
    }

    if (
      highScore.perfectCount === maxScore.perfectCount &&
      highScore.timeTaken < maxScore.timeTaken
    ) {
      maxScore = highScore;
      continue;
    }
  }

  const first =
    maxScore !== undefined
      ? !highScores.some(
          (highScore) =>
            highScore.perfectCount > maxScore!.perfectCount ||
            (highScore.perfectCount === maxScore?.perfectCount &&
              highScore.timeTaken < maxScore.timeTaken)
        )
      : false;

  return maxScore ? { ...maxScore, first } : undefined;
};

class GroupPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    this.fetchGroup();
  }

  async fetchGroup() {
    const { groupUniqueName } = this.props;
    if (groupUniqueName === undefined) {
      throw Error("Unexpected state");
    }

    const groupResponse = await api.getGroup(
      groupUniqueName,
      utils.daysSinceStartDate()
    );
    this.setState({ ...this.state, groupResponse });
  }

  footerButtons(): FooterButtonModel[] {
    const { groupResponse } = this.state;

    let photosCallout: string | undefined;
    let editGroupCallout: string | undefined;

    if (groupResponse !== undefined) {
      if (groupResponse.days.length === 0) {
        photosCallout = `${
          utils.isTouchDevice() ? "Tap" : "Click"
        } here to add photos`;
      } else if (groupResponse.members.length <= 1) {
        editGroupCallout = `${
          utils.isTouchDevice() ? "Tap" : "Click"
        } here to invite members`;
      } else if (groupResponse.upcomingPhotoCount.days <= 1) {
        photosCallout = `Photos running low! ${
          utils.isTouchDevice() ? "Tap" : "Click"
        } to add more`;
      }
    }

    return [
      {
        name: "Add Photos",
        action: () => route(`/j/${this.props.groupUniqueName}/addPhotos`),
        type: "secondary",
        callout: photosCallout,
      },
      ...(this.state.groupResponse?.admin
        ? [
            {
              name: "Edit Group",
              action: () => {
                console.log("Edit group");
                route(`/j/${this.props.groupUniqueName}/admin`);
              },
              type: "secondary" as const,
              callout: editGroupCallout,
            },
          ]
        : []),
    ];
  }

  render() {
    const { user } = this.props;
    const groupResponse = this.state.groupResponse;

    if (groupResponse === undefined) {
      return <div>Loading...</div>;
    }

    const lastDay = _.last(groupResponse.days);

    let nextDayTeaser = false;

    if (lastDay !== undefined) {
      const todayHighScore = getHighScoreForUser(lastDay.highScores, user.id);
      nextDayTeaser = todayHighScore !== undefined;
    }

    return (
      <div>
        <MenuPageContainer
          user={user}
          group={groupResponse.uniqueName}
          background={user === undefined ? "blue" : "white"}
          pageTitle={groupResponse.displayName}
          backLink={{ type: "home" }}
          mode="scroll"
          footerButtons={this.footerButtons()}
        >
          <div class={style.body}>
            {lastDay && nextDayTeaser ? (
              // <NextDayTeaser date={utils.dayToDate(lastDay.day + 1)} />
              <p class={style.teaser}>Come back tomorrow for more!</p>
            ) : null}
            {groupResponse.days.reverse().map((day, index) => (
              <GroupSessionItem
                group={{
                  id: groupResponse.id,
                  uniqueName: groupResponse.uniqueName,
                  displayName: groupResponse.displayName,
                }}
                day={day.day}
                groupIndex={0}
                mode={
                  day.day === utils.daysSinceStartDate()
                    ? "today"
                    : "previous-day"
                }
                // onClick={() => {
                //   console.log("clicked day: ", day.day);
                //   route(`/j/${groupResponse.uniqueName}/puzzle`);
                // }}
                coverPhotoUrl={photoUrl(
                  groupResponse.id,
                  day.photos?.[0]?.fileHash ?? "not found"
                )}
                highScore={getHighScoreForUser(day.highScores, user.id)}
              />
            ))}
          </div>
        </MenuPageContainer>
        {/* <FooterButtons buttons={this.footerButtons()} mode="scroll" /> */}
      </div>
    );
  }
}

export default GroupPage;
