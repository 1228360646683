import * as _ from "lodash";
import { createRef, Component, h } from "preact";
import { route } from "preact-router";

import { GetGroupResponse } from "../../../shared/apiTypes";
import { Photo, User } from "../../../shared/types";
import { photoUrl } from "../../../shared/utils";

import * as api from "../api";
import Timer from "../components/Timer";
import TopBarMenu from "../components/TopBarMenu";
import style from "../styles/PuzzlePage.module.scss";
import * as tracking from "../tracking";
import { cn, daysSinceStartDate } from "../utils";

interface BoardPosition {
  boardX: number;
  boardY: number;
}

interface State {
  photos: Photo[] | undefined;
  results: boolean[];
  pieces: BoardPosition[][];
  selectedPiece:
    | {
        x: number;
        y: number;
      }
    | undefined;
  complete: boolean;
  previouslySwapped: { x: number; y: number }[];
  active: boolean;
  windowSize: {
    width: number;
    height: number;
  };
  photoIndex: number;
  stage: "startScreen" | "inProgress" | "sessionEnd";
  failCount: number;
  difficulty: number;
  xSize: number;
  ySize: number;
  draggingPosition:
    | {
        x: number;
        y: number;
      }
    | undefined;
  randomOrientations: number[][];
  startTime?: number;
  endTime?: number;
  nextButtonTimes: {
    start: number;
    end?: number;
  }[];
  // This enables cycling through season colors on start page.
  startSeasonCount: number;
  group: GetGroupResponse | undefined;
}

const isComplete = (pieces: BoardPosition[][]) => {
  let mistakeFound = false;
  pieces.forEach((column, trueX) =>
    column.forEach((piece, trueY) => {
      if (piece.boardX !== trueX || piece.boardY !== trueY) {
        mistakeFound = true;
      }
    })
  );
  return !mistakeFound;
};

const startingDifficulty = 2;

const calcBoardSize = (
  difficulty: number
): { xSize: number; ySize: number } => ({
  xSize: 3 + Math.floor(difficulty / 3),
  ySize: 3 + Math.ceil(difficulty / 3),
});

interface Props {
  user?: User;
  group?: string;
  demoMode?: boolean;
}

/** This adjusts the position of the piece you are currently dragging */
const touchMode = false;

/** The number of pixels to offset vertically (should be same as the drop shadow offset) */
const touchModeOffset = 30;

class PuzzlePage extends Component<Props, State> {
  aspectRatio: number = 1;

  borderRadius = 48;

  mouseMoveThrottled: (x: number, y: number) => void;

  puzzleContainerRef = createRef<HTMLDivElement>();

  // XXX For debugging
  debugTouchEvent: h.JSX.TargetedTouchEvent<HTMLDivElement> | undefined;

  constructor(props: Props) {
    super(props);

    const { xSize, ySize } = calcBoardSize(0);

    const initialState: State = {
      photos: undefined,
      results: [],
      pieces: [],
      selectedPiece: undefined,
      complete: false,
      previouslySwapped: [],
      active: false,
      windowSize: this.windowSize(),
      photoIndex: -1,
      stage: "startScreen",
      failCount: 0,
      difficulty: startingDifficulty - (props.demoMode ? 1 : 0),
      xSize,
      ySize,
      draggingPosition: undefined,
      randomOrientations: [],
      startTime: undefined,
      nextButtonTimes: [],
      endTime: undefined,
      startSeasonCount: 0,
      group: undefined,
    };

    this.state = initialState;

    window.onresize = () => {
      this.setState({ ...this.state, windowSize: this.windowSize() });
    };

    this.mouseMoveThrottled = _.throttle(this.mouseMove, 30);
    console.log("constructor");

    if (props.demoMode) {
      // Not using setState() since we're in the constructor.
      this.state = { ...this.state, photos: [] };
      this.startSession();
    } else {
      this.fetchPhotos();
    }
  }

  async fetchPhotos() {
    if (this.props.group === undefined) {
      return;
    }

    const group = await api.getGroup(this.props.group, daysSinceStartDate());
    const session = await api.getSession(group.id, daysSinceStartDate());

    if (session.status === "not-enough-photos") {
      alert("XXX not enough photos");
      return;
    }

    this.setState(
      {
        ...this.state,
        photos: session.photos,
        group,
      },
      () => {
        this.startSession();
      }
    );
  }

  async fetchGroup() {
    if (this.props.group === undefined) {
      return;
    }

    const group = await api.getGroup(this.props.group, daysSinceStartDate());
    this.setState({ ...this.state, group }, () => {
      this.startSession();
    });
  }

  startSession() {
    setTimeout(() => {
      // Hard code today's photo set:
      this.setState(
        {
          ...this.state,
          photoIndex: 0,
        },
        () => {
          this.initializeBoard(0);
        }
      );
    }, 50);
  }

  initialBodyOverflowStyle: string = "auto";

  componentWillMount() {
    this.initialBodyOverflowStyle = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    window.addEventListener("blur", () => {
      this.mouseUp();
    });
    window.addEventListener("dragleave", () => {
      this.mouseUp();
    });
    document.addEventListener("mouseleave", () => {
      this.mouseUp();
    });
  }

  startIntervalId: any;

  componentDidMount() {
    document.title = "Puzzle - JigglePix";
    tracking.track({ event: "puzzleSessionStart", properties: {} });

    // Cycle through start seasons
    this.startIntervalId = setInterval(() => {
      if (this.state.stage === "startScreen") {
        this.setState({
          ...this.state,
          startSeasonCount: (this.state.startSeasonCount + 1) % 4,
        });
      }
    }, 2000);
  }

  componentWillUnmount() {
    document.body.style.overflow = this.initialBodyOverflowStyle;
    window.removeEventListener("blur", this.mouseUp);
  }

  initializeBoard(photoIndex: number) {
    const board: BoardPosition[][] = [];

    const { xSize, ySize } = calcBoardSize(this.state.difficulty);

    for (let x = 0; x < xSize; x++) {
      board[x] = [];
      for (let y = 0; y < ySize; y++) {
        board[x][y] = { boardX: x, boardY: y };
      }
    }

    // XXX this seems messy, fix.
    const initialState: State = {
      ...this.state,
      photos: this.state.photos,
      results: this.state.results,
      pieces: board,
      selectedPiece: undefined,
      complete: false,
      previouslySwapped: [],
      active: false,
      windowSize: this.windowSize(),
      photoIndex,
      stage: "inProgress",
      failCount: 0,
      difficulty: this.state.difficulty,
      xSize,
      ySize,
      draggingPosition: undefined,
      randomOrientations: (() =>
        _.range(xSize).map(() =>
          _.range(ySize).map(() => {
            // Return a random number in the range [-max, -min] OR [+min, +max], we don't want
            // the number to be too close to 0.
            const min = 2;
            const max = 5;
            return Math.random() < 0.5
              ? -max + (max - min) * Math.random()
              : min + (max - min) * Math.random();
          })
        ))(),
      nextButtonTimes: this.state.nextButtonTimes,
    };

    this.setState(initialState);

    const waitForShuffle = 3000;

    setTimeout(() => this.doShuffleSwap(), waitForShuffle);
  }

  windowSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  boardSize() {
    const margin = 8;
    /**
     * Width of the board. We subtract 1 here so that the right hand edge ends with a black line
     * just like the left hand edge.
     */
    const width = Math.min(600, this.state.windowSize.width - 2 * margin) - 1;

    return {
      width: width,
      height: width / this.aspectRatio,
    };
  }

  doShuffleSwap() {
    const piecesStillInPlace: {
      x: number;
      y: number;
    }[] = [];

    this.state.pieces.forEach((column, x) =>
      column.forEach((piece, y) => {
        if (x === piece.boardX && y === piece.boardY) {
          piecesStillInPlace.push({ x, y });
        }
      })
    );

    if (piecesStillInPlace.length === 0) {
      let nextButtonTimes = this.state.nextButtonTimes;
      const lastNextButtonTime = nextButtonTimes[nextButtonTimes.length - 1];
      if (lastNextButtonTime && lastNextButtonTime.end === undefined) {
        nextButtonTimes = [
          ...nextButtonTimes.slice(0, nextButtonTimes.length - 1),
          {
            start: lastNextButtonTime.start,
            end: new Date().getTime(),
          },
        ];
      }

      // Finished, activate puzzle
      this.setState({ ...this.state, active: true, nextButtonTimes });
      return;
    }

    // Choose random piece to swap from the pieces which are still in place:
    const { x: x1, y: y1 } =
      piecesStillInPlace[Math.floor(Math.random() * piecesStillInPlace.length)];

    let x2: number = x1;
    let y2: number = y1;

    while (x2 === x1 && y2 === y1) {
      // Choose random other piece
      x2 = Math.floor(Math.random() * this.state.xSize);
      y2 = Math.floor(Math.random() * this.state.ySize);
    }

    const tempPosition = this.state.pieces[x2][y2];
    let updatedPieces: BoardPosition[][] = [
      ...this.state.pieces.slice(0, x2),
      [
        ...this.state.pieces[x2].slice(0, y2),
        this.state.pieces[x1][y1],
        ...this.state.pieces[x2].slice(y2 + 1),
      ],
      ...this.state.pieces.slice(x2 + 1),
    ];
    updatedPieces = [
      ...updatedPieces.slice(0, x1),
      [
        ...updatedPieces[x1].slice(0, y1),
        tempPosition,
        ...updatedPieces[x1].slice(y1 + 1),
      ],
      ...updatedPieces.slice(x1 + 1),
    ];
    this.setState({
      ...this.state,
      pieces: updatedPieces,
    });

    const delay = 50;

    // Call again after timeout
    setTimeout(() => this.doShuffleSwap(), delay);
  }

  getHoveredLocation() {
    if (!this.state.draggingPosition) {
      return undefined;
    }

    const relativeX = this.state.draggingPosition.x / this.boardSize().width;
    const relativeY = this.state.draggingPosition.y / this.boardSize().height;

    return {
      x: Math.floor(this.state.xSize * relativeX),
      y: Math.floor(this.state.ySize * relativeY),
    };
  }

  puzzlePiece(trueX: number, trueY: number, piece: BoardPosition) {
    if (this.state.photos === undefined) {
      throw Error("Unexpected state");
    }

    const isSelected =
      this.state.selectedPiece &&
      this.state.selectedPiece.x === trueX &&
      this.state.selectedPiece.y === trueY;

    const width = this.boardSize().width / this.state.xSize;
    const height = this.boardSize().height / this.state.ySize;

    let cornerStyleName: string = "";
    if (trueX === 0 && trueY === 0) {
      cornerStyleName = style.puzzlePieceTopLeft;
    } else if (trueX === this.state.xSize - 1 && trueY === 0) {
      cornerStyleName = style.puzzlePieceTopRight;
    } else if (trueX === 0 && trueY === this.state.ySize - 1) {
      cornerStyleName = style.puzzlePieceBottomLeft;
    } else if (
      trueX === this.state.xSize - 1 &&
      trueY === this.state.ySize - 1
    ) {
      cornerStyleName = style.puzzlePieceBottomRight;
    }

    const draggingPosition = this.state.draggingPosition;

    const stylePosition =
      isSelected && draggingPosition
        ? {
            transition: "none",
            top: draggingPosition.y - height * (touchMode ? 0.5 : 0.5),
            left: draggingPosition.x - width * 0.5,
            zIndex: 2,
          }
        : {
            top: (this.boardSize().height * piece.boardY) / this.state.ySize,
            left: (this.boardSize().width * piece.boardX) / this.state.xSize,
          };

    const hoveredLocation = this.getHoveredLocation();

    // Check if this piece is being hovered over
    const isHoveredOver =
      !isSelected &&
      hoveredLocation &&
      hoveredLocation.x === piece.boardX &&
      hoveredLocation.y === piece.boardY;

    const pieceInCorrectPlace =
      trueX === piece.boardX && trueY === piece.boardY;
    const pieceComplete = this.state.active && pieceInCorrectPlace;

    return (
      <div
        key={`${trueX}-${trueY}`}
        class={cn([
          style.puzzlePiece,
          cornerStyleName,
          isSelected ? style.selected : undefined,
          this.state.previouslySwapped.some(
            (piece) => piece.x === trueX && piece.y === trueY
          )
            ? style.swapping
            : undefined,
          pieceComplete ? style.puzzlePieceComplete : undefined,
        ])}
        style={{
          width,
          height,
          backgroundPositionX: `${(100 * trueX) / (this.state.xSize - 1)}%`,
          backgroundPositionY: `${(100 * trueY) / (this.state.ySize - 1)}%`,
          backgroundSize: `${this.boardSize().width}px ${
            this.boardSize().height
          }px`,
          backgroundImage: `url(${this.photoUrl(this.state.photoIndex)})`,
          ...stylePosition,
          border: isHoveredOver ? "dashed 4px #000" : "none",
          transform: pieceInCorrectPlace
            ? null
            : `rotate(${this.state.randomOrientations[trueX][trueY]}deg)`,
        }}
        onMouseDown={(event) =>
          this.startDrag(trueX, trueY, event.clientX, event.clientY)
        }
        onTouchStart={(event) =>
          this.startDrag(
            trueX,
            trueY,
            event.touches[0].clientX,
            event.touches[0].clientY
          )
        }
      ></div>
    );
  }

  handleClick(trueX: number, trueY: number) {
    if (!this.state.complete) {
      this.selectPiece(trueX, trueY);
    }
  }

  startDrag(trueX: number, trueY: number, mouseX: number, mouseY: number) {
    let containerX: number = 0;
    let containerY: number = 0;

    if (this.puzzleContainerRef.current) {
      containerX = this.puzzleContainerRef.current.getBoundingClientRect().x;
      containerY = this.puzzleContainerRef.current.getBoundingClientRect().y;
    }

    this.selectPiece(trueX, trueY, {
      x: mouseX - containerX,
      y: mouseY - containerY - (touchMode ? touchModeOffset : 0),
    });
  }

  mouseMove(x: number, y: number) {
    let containerX: number = 0;
    let containerY: number = 0;

    if (this.puzzleContainerRef.current) {
      containerX = this.puzzleContainerRef.current.getBoundingClientRect().x;
      containerY = this.puzzleContainerRef.current.getBoundingClientRect().y;
    }

    if (this.state.draggingPosition) {
      this.setState({
        ...this.state,
        draggingPosition: {
          x: x - containerX,
          y: y - containerY - (touchMode ? touchModeOffset : 0),
        },
      });
    }
  }

  mouseUp() {
    const { xSize, ySize } = this.state;
    const hoveredLocation = this.getHoveredLocation();

    if (
      !hoveredLocation ||
      hoveredLocation.x < 0 ||
      hoveredLocation.x >= xSize ||
      hoveredLocation.y < 0 ||
      hoveredLocation.y >= ySize
    ) {
      // Cancel
      this.setState({
        ...this.state,
        draggingPosition: undefined,
        selectedPiece: undefined,
      });
      return;
    }

    // TODO: Get trueX and trueY of hoveredLocation to call selectPiece()
    let trueX: number | undefined;
    let trueY: number | undefined;

    this.state.pieces.forEach((column, x) => {
      column.forEach((piece, y) => {
        if (
          piece.boardX === hoveredLocation.x &&
          piece.boardY === hoveredLocation.y
        ) {
          trueX = x;
          trueY = y;
        }
      });
    });

    if (trueX !== undefined && trueY !== undefined) {
      this.selectPiece(trueX, trueY);
    }
  }

  reachedEnd() {
    const { complete, photoIndex, photos } = this.state;
    if (photos === undefined) {
      throw Error("Unexpected state");
    }
    return complete && photoIndex >= photos.length - 1;
  }

  userHasSwapped() {
    return (
      this.state.photoIndex > 0 ||
      this.state.pieces.some((column, x) =>
        column.some((piece, y) => piece.boardX === x && piece.boardY === y)
      )
    );
  }

  selectPiece(
    trueX: number,
    trueY: number,
    draggingPosition?: { x: number; y: number }
  ) {
    const { selectedPiece, pieces } = this.state;

    if (selectedPiece !== undefined) {
      const oldSelectedPiece =
        this.state.pieces[selectedPiece.x][selectedPiece.y];
      const newSelectedPiece = pieces[trueX][trueY];

      if (oldSelectedPiece === newSelectedPiece) {
        // User is cancelling the move
        this.setState({
          ...this.state,
          selectedPiece: undefined,
          draggingPosition: undefined,
        });
        return;
      }

      // Check if this is a good move, meaning that one of the two pieces is now in the correct
      // location
      const oldPieceCorrect =
        newSelectedPiece.boardX === selectedPiece.x &&
        newSelectedPiece.boardY === selectedPiece.y;
      const newPieceCorrect =
        oldSelectedPiece.boardX === trueX && oldSelectedPiece.boardY === trueY;

      if (!oldPieceCorrect && !newPieceCorrect) {
        this.setState({
          ...this.state,
          failCount: this.state.failCount + 1,
          selectedPiece: undefined,
          draggingPosition: undefined,
        });
        return;
      }

      // Do a swap
      const newPieces: BoardPosition[][] = pieces.map((column, x) =>
        column.map((piece, y) => {
          if (x === trueX && y === trueY) {
            return oldSelectedPiece;
          } else if (x === selectedPiece!.x && y === selectedPiece!.y) {
            return newSelectedPiece;
          } else {
            return piece;
          }
        })
      );

      const completed = isComplete(newPieces);

      let incrementStreak = false;
      let results: boolean[];
      let nextButtonTimes: { start: number; end?: number }[] =
        this.state.nextButtonTimes;

      if (!this.state.complete && completed) {
        if (this.state.failCount === 0) {
          incrementStreak = true;
          results = [...this.state.results, true];
        } else {
          results = [...this.state.results, false];
        }

        nextButtonTimes = [...nextButtonTimes, { start: new Date().getTime() }];
      } else {
        results = [...this.state.results];
      }

      this.setState({
        ...this.state,
        pieces: newPieces,
        selectedPiece: undefined,
        draggingPosition: undefined,
        complete: completed,
        previouslySwapped: [
          {
            x: selectedPiece.x,
            y: selectedPiece.y,
          },
          {
            x: trueX,
            y: trueY,
          },
        ],
        difficulty: incrementStreak
          ? this.state.difficulty + 1
          : this.state.difficulty,
        results,
        startTime:
          this.state.startTime === undefined
            ? new Date().getTime()
            : this.state.startTime,
        nextButtonTimes,
      });
    } else {
      this.setState({
        ...this.state,
        selectedPiece: { x: trueX, y: trueY },
        draggingPosition,
      });
    }
  }

  async continue() {
    const { photoIndex, photos } = this.state;
    if (photos === undefined) {
      throw Error("Unexpected state");
    }

    const numberDemoPhotos = 2;

    if (this.props.demoMode) {
      if (photoIndex < numberDemoPhotos - 1) {
        this.initializeBoard(photoIndex + 1);
      } else {
        route("/demoEnd");
      }
      return;
    }

    if (photoIndex >= photos.length - 1) {
      tracking.track({ event: "puzzle_session_end", properties: {} });
      this.setState(
        {
          ...this.state,
          endTime:
            this.state.nextButtonTimes[this.state.nextButtonTimes.length - 1]
              .start,
        },
        () => {
          this.submitAndLoadHighScores();
        }
      );
      return;
    }
    this.initializeBoard(photoIndex + 1);
    console.log("results: ", this.state.results);
  }

  initMessage(message: string) {
    return <p>{message}</p>;
  }

  photoUrl(index: number): string | undefined {
    if (this.props.demoMode) {
      return `/assets/demoPhotos/${index + 1}.jpg`;
    }

    if (this.state.photos === undefined || this.state.group === undefined) {
      throw new Error("Unexpected state");
    }

    return photoUrl(this.state.group.id, this.state.photos[index].fileHash);
  }

  async logout() {
    await api.logout();
    window.location.reload();
  }

  render() {
    const { demoMode, group } = this.props;
    const { photos } = this.state;

    if (photos === undefined) {
      return <div>Loading...</div>;
    }

    if (!demoMode && group === undefined) {
      throw Error("Unexpected state");
    }

    // XXX Need to take center column into account I think
    const top = (this.state.windowSize.height - this.boardSize().height) / 2;

    const surroundingPiecesScaleFactor = 0.75;

    const spaceBetweenPhotos = 32;

    return (
      <div
        class={cn([
          style.page,
          this.state.photoIndex >= 0
            ? style[
                ["spring", "summer", "autumn", "winter"][
                  this.state.photoIndex % 4
                ]
              ]
            : style[
                ["spring", "summer", "autumn", "winter"][
                  this.state.startSeasonCount
                ]
              ],
        ])}
        onMouseUp={() => this.mouseUp()}
        onTouchEnd={() => this.mouseUp()}
      >
        {!demoMode ? (
          <img
            onClick={() => {
              if (
                this.state.startTime !== undefined &&
                !confirm("Are you sure you want to quit?")
              ) {
                return;
              }
              route(`/j/${group}`);
            }}
            class={style.closeButton}
            src="/assets/closeButton.svg"
          />
        ) : null}
        <div class={style.progressBar}>
          {photos.map((_photoId, index) => {
            return (
              <div
                class={cn([
                  index === this.state.photoIndex
                    ? style.progressBarCurrent
                    : undefined,
                  index >= this.state.results.length
                    ? style.progressBarIncomplete
                    : this.state.results[index]
                    ? style.progressBarPerfect
                    : style.progressBarNotPerfect,
                ])}
              >
                {index === this.state.photoIndex &&
                this.state.results[index] === undefined ? (
                  <div class={style.progressNumber}>{index + 1}</div>
                ) : (
                  <img src={this.photoUrl(index)} />
                )}
                {this.state.results[index] ? (
                  <div class={style.perfectEmojiOverlay}>⭐</div>
                ) : null}
                {this.state.results[index] === false ? (
                  <div class={style.completeOverlay}>✓</div>
                ) : null}
              </div>
            );
          })}
          {!demoMode &&
          this.state.stage === "startScreen" &&
          this.state.group ? (
            <div class={style.hamburgerMenu}>
              <TopBarMenu
                logout={() => this.logout()}
                group={this.state.group}
              />
            </div>
          ) : null}
        </div>
        {this.state.stage === "inProgress" ? (
          <div class={style.timer}>
            {!demoMode &&
            this.state.startTime === undefined &&
            this.state.active ? (
              "Drag the pieces into the right place"
            ) : !this.state.active ? (
              // "Take a close look!"
              <div class={style.previewTimerContainer}>
                <div class={style.previewTimer} />
              </div>
            ) : !demoMode &&
              this.state.active &&
              this.state.startTime !== undefined &&
              !this.state.complete ? (
              <Timer
                key={this.state.nextButtonTimes.length}
                startTime={this.state.startTime!}
                endTime={this.state.endTime}
                nextButtonTimes={this.state.nextButtonTimes}
              />
            ) : null}
          </div>
        ) : null}
        {demoMode ? (
          <div class={style.demoInstructions}>
            {!this.state.active
              ? this.state.photoIndex === 0
                ? "Let's start with a quick demo!"
                : "Take a close look"
              : this.state.photoIndex === 0 && !this.state.complete
              ? "Drag the pieces into the right place"
              : this.state.photoIndex === 1 && !this.state.results[0]
              ? "This time, try without making any mistakes!"
              : this.state.photoIndex === 1 && this.state.results[0]
              ? "Drag the pieces into the right place"
              : null}
          </div>
        ) : null}
        <div
          class={style.centerColumn}
          onMouseMove={(event) => {
            this.mouseMoveThrottled(event.clientX, event.clientY);
          }}
          onTouchMove={(event) => {
            // This is required on iOS 12 to prevent the page scrolling when dragging pieces, which
            // messes with our drag-and-drop code.
            event.preventDefault();
            event.stopPropagation();

            this.debugTouchEvent = event;

            this.mouseMoveThrottled(
              event.touches[0].clientX,
              event.touches[0].clientY
            );
          }}
        >
          {this.state.photoIndex - 1 >= 0 ? (
            <img
              key={photos[this.state.photoIndex - 1]}
              src={this.photoUrl(this.state.photoIndex - 1)}
              className={style.previousPhoto}
              style={{
                width: this.boardSize().width * surroundingPiecesScaleFactor,
                height: this.boardSize().height * surroundingPiecesScaleFactor,
                top:
                  this.windowSize().height / 2 -
                  (this.boardSize().height * surroundingPiecesScaleFactor) / 2,
                borderRadius: this.borderRadius * surroundingPiecesScaleFactor,
                marginRight: spaceBetweenPhotos,
              }}
            />
          ) : null}
          {this.state.photoIndex < 0 ? (
            <div class={style.introMessage}>
              <h2>
                <img
                  src="/assets/jigglePixLogo.svg"
                  class={style.jigglePixLogo}
                />
              </h2>
              {this.state.group ? (
                <p>
                  Group: {this.state.group.displayName} (
                  {this.state.group.members.length} member
                  {this.state.group.members.length === 1 ? "" : "s"})
                </p>
              ) : null}
            </div>
          ) : (
            <div
              key={photos[this.state.photoIndex]}
              class={cn([
                style.puzzleContainer,
                this.state.complete ? style.complete : undefined,
              ])}
              style={{
                width: this.boardSize().width,
                height: this.boardSize().height,
                top,
              }}
              ref={this.puzzleContainerRef}
            >
              {this.state.failCount > 0 ? (
                <div key={this.state.failCount} class={style.failFlash} />
              ) : null}
              {this.state.pieces.flatMap((column, boardX) =>
                column.map((piece, boardY) =>
                  this.puzzlePiece(boardX, boardY, piece)
                )
              )}
              {this.state.complete ? (
                this.state.failCount === 0 ? (
                  <div class={style.perfectMessage}>PERFECT! ⭐</div>
                ) : (
                  <div class={style.perfectMessage}>NICE! ✓</div>
                )
              ) : null}
              {this.state.complete && !demoMode ? (
                <div class={style.uploadedByOverlay}>
                  Photo added by {photos[this.state.photoIndex].userName}
                </div>
              ) : null}
            </div>
          )}
          {this.state.photoIndex + 1 < photos.length ? (
            <img
              key={photos[this.state.photoIndex + 1]}
              src={this.photoUrl(this.state.photoIndex + 1)}
              className={style.nextPhoto}
              style={{
                width: this.boardSize().width * surroundingPiecesScaleFactor,
                height: this.boardSize().height * surroundingPiecesScaleFactor,
                top:
                  this.windowSize().height / 2 -
                  (this.boardSize().height * surroundingPiecesScaleFactor) / 2,
                borderRadius: this.borderRadius * surroundingPiecesScaleFactor,
                marginLeft: spaceBetweenPhotos,
              }}
            />
          ) : null}
          {this.state.complete ? (
            this.state.photoIndex < photos.length - 1 ? (
              <button class={style.nextButton} onClick={() => this.continue()}>
                NEXT
              </button>
            ) : (
              // This could be styled different to indicate session end?
              <button class={style.nextButton} onClick={() => this.continue()}>
                NEXT
              </button>
            )
          ) : null}
        </div>
        {this.invisiblePreFetchedPhotos()}
      </div>
    );
  }

  /**
   * Include all images so that they will be cached for later, in case the user returns to
   * this page a while later when the URLs have expired.
   * Alternative approach could be to re-fetch from the server if a certain amount of time has
   * elapsed
   */
  invisiblePreFetchedPhotos() {
    if (this.state.photos === undefined) {
      throw Error("Unexpected state");
    }

    return (
      <div class={style.invisiblePreFetchedPhotos}>
        {this.state.photos.map((_photoId, index) => (
          <img src={this.photoUrl(index)} />
        ))}
        <img />
      </div>
    );
  }

  groupId() {
    if (this.state.group === undefined) {
      throw Error("Unexpected state");
    }

    return this.state.group.id;
  }

  lastCompletedDayKey() {
    return `${this.groupId()}-lastCompletedDay`;
  }

  async submitAndLoadHighScores() {
    const perfectCount = this.state.results.filter((result) => result).length;
    const timeTaken =
      (this.state.endTime! -
        this.state.startTime! -
        this.state.nextButtonTimes.reduce((total, nextButtonTimes) => {
          if (nextButtonTimes.end) {
            return total + nextButtonTimes.end - nextButtonTimes.start;
          }
          return total;
        }, 0)) /
      1000;
    await api.postHighScore({
      groupId: this.groupId(),
      day: daysSinceStartDate(),
      perfectCount,
      timeTaken,
    });

    localStorage[this.lastCompletedDayKey()] = daysSinceStartDate();

    route(
      `/j/${
        this.props.group
      }/scores/${daysSinceStartDate()}?stars=${perfectCount}&timeTaken=${timeTaken}`
    );
  }
}

export default PuzzlePage;
