/**
 * A sideways scrolling overlay showing the high scores for a given day, a bit like a stock
 * ticker on a TV news programme.
 */
import * as _ from "lodash";
import { Component, createRef, h } from "preact";

import { HighScore, User } from "../../../shared/types";

import style from "../styles/HighScoreTicker.module.scss";
import * as utils from "../utils";

interface Props {
  user: User;
  highScores: HighScore[];
  showingUserScoreFirst: boolean;
}

interface State {}

class HighScoreTicker extends Component<Props, State> {
  containerRef = createRef<HTMLDivElement>();
  tickerRef = createRef<HTMLDivElement>();
  distanceToScroll: number | undefined;
  timeOfScroll: number | undefined;
  /** Time to pause in seconds */
  timeToPause = 3;
  /** Margin to left and right of ticker in pixels */
  margin = 16;

  scrollTimeoutId: any;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  deduplicateScores() {
    const deDuplicatedScores: (HighScore & {
      attempts: number;
      order: number;
    })[] = [];

    const nameAddedIndex: { [name: string]: number } = {};

    for (const score of this.props.highScores) {
      if (nameAddedIndex[score.name] !== undefined) {
        // Already added so increment number of attempts
        deDuplicatedScores[nameAddedIndex[score.name]].attempts++;
        continue;
      }

      nameAddedIndex[score.name] = deDuplicatedScores.length;
      deDuplicatedScores.push({
        ...score,
        attempts: 1,
        order: deDuplicatedScores.length + 1,
      });
    }

    return deDuplicatedScores;
  }

  scoresByStars(): {
    stars: number;
    users: {
      name: string;
      timeTaken: number;
      attempts: number;
      userId: number;
      order: number;
    }[];
  }[] {
    const deduplicatedScores = this.deduplicateScores();

    const scoresByStars: {
      stars: number;
      users: {
        name: string;
        timeTaken: number;
        attempts: number;
        userId: number;
        order: number;
      }[];
    }[] = _.range(6).map((stars) => ({ stars, users: [] }));

    for (const score of deduplicatedScores) {
      scoresByStars[score.perfectCount].users.push({
        name: score.name,
        timeTaken: score.timeTaken,
        attempts: score.attempts,
        userId: score.userId,
        order: score.order,
      });
    }

    return scoresByStars.filter((group) => group.users.length > 0).reverse();
  }

  render() {
    const user = this.props.user;
    const {} = this.state;
    const testScoresByStars = [
      {
        stars: 5,
        users: [
          {
            name: "John",
            timeTaken: 100,
          },
          {
            name: "Steve Ridout III Son of Zeux, The Greatest",
            timeTaken: 987,
          },
        ],
      },
      {
        stars: 3,
        users: [
          {
            name: "Elisa",
            timeTaken: 80,
          },
          {
            name: "Steve Ridout III Son of Zeux, The Greatest",
            timeTaken: 987,
          },
        ],
      },
      {
        stars: 0,
        users: [
          {
            name: "Ania",
            timeTaken: 80,
          },
          {
            name: "Steve Ridout IV Son of Fnupe, The Lamest",
            timeTaken: 9843,
          },
        ],
      },
    ];

    const scoresByStars = this.scoresByStars();
    // const scoresByStars = testScoresByStars;

    return (
      <div class={style.container} ref={this.containerRef}>
        <div class={style.highScoreTicker} ref={this.tickerRef}>
          <span class={style.title}>
            High
            <br />
            Scores
          </span>
          {scoresByStars.map((group) => (
            <span class={style.group}>
              {group.users.map((userScoreInfo) => (
                <div
                  class={utils.cn([
                    style.user,
                    userScoreInfo.userId === user.id
                      ? style.userHighlight
                      : undefined,
                  ])}
                >
                  <div class={style.position}>
                    {this.ordinalDisplayString(userScoreInfo.order)}
                  </div>
                  <div>{userScoreInfo.name}</div>
                  {scoresByStars.length > 1 || scoresByStars[0]?.stars > 0 ? (
                    <div>{this.renderStars(group.stars)}</div>
                  ) : null}
                  <div>{userScoreInfo.timeTaken}s</div>
                </div>
              ))}
            </span>
          ))}
        </div>
      </div>
    );
  }

  ordinalDisplayString(n: number) {
    const strings = [undefined, "st", "nd", "rd"];
    const mod100 = n % 100;

    if (mod100 >= 20) {
      return `${n}${strings[mod100 % 10] ?? "th"}`;
    }

    return `${n}${strings[mod100] ?? "th"}`;
  }

  renderStars(stars: number) {
    return (
      <span class={style.stars}>
        {stars > 0 ? _.range(stars).map(() => "⭑") : "\u00a0"}
      </span>
    );
  }

  componentDidMount() {
    this.setUpScrolling();
    window.addEventListener("resize", this.setUpScrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setUpScrolling);
  }

  setUpScrolling = () => {
    console.log("setUpScrolling");
    clearTimeout(this.scrollTimeoutId);

    const container = this.containerRef.current;
    const ticker = this.tickerRef.current;
    console.log("container width: ", container?.clientWidth);

    if (!container || !ticker) {
      return;
    }

    // Reset the ticker to the left margin
    ticker.style["transition"] = "none";
    ticker.style["left"] = `${this.margin}px`;

    // XXX the following should be recalculated in case of browser window resize
    const margin = 16;

    const tickerWidth = ticker.clientWidth;
    const containerWidth = container.clientWidth;

    this.distanceToScroll = tickerWidth + 2 * margin - containerWidth;

    if (this.distanceToScroll < 0) {
      return;
    }

    /** Speed in pixels per second */
    const speed = 60;
    this.timeOfScroll = this.distanceToScroll / speed;

    ticker.style["transition"] = `left ${this.timeOfScroll}s linear`;

    /** This should match the equivalent value in constants.scss */
    const sessionEndCardTime = this.props.showingUserScoreFirst ? 5 : 0;

    this.scrollTimeoutId = setTimeout(() => {
      this.updateScrolling();
    }, (sessionEndCardTime + this.timeToPause) * 1000);
  };

  updateScrolling() {
    const ticker = this.tickerRef.current;
    if (
      !ticker ||
      this.distanceToScroll === undefined ||
      this.timeOfScroll === undefined
    ) {
      return;
    }

    ticker.style["left"] = `-${this.distanceToScroll}px`;

    const time = (this.timeOfScroll + this.timeToPause) * 1000;

    this.scrollTimeoutId = setTimeout(() => {
      ticker.style["left"] = `${this.margin}px`;

      this.scrollTimeoutId = setTimeout(() => {
        this.updateScrolling();
      }, time);
    }, time);
  }
}

export default HighScoreTicker;
