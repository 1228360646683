import { FunctionalComponent, h } from "preact";

import MenuPageContainer from "../components/MenuPageContainer";
import WhiteCard from "../components/WhiteCard";
import AuthForm from "../components/AuthForm";

const LoginPage: FunctionalComponent<{}> = () => {
  return (
    <MenuPageContainer
      background="green"
      backLink={{ type: "none" }}
      mode="scroll"
    >
      <WhiteCard narrow={true}>
        <h2>Login to JigglePix</h2>
        <AuthForm
          key="login"
          mode="login"
          loggedIn={() => (window.location.href = "/")}
        />
      </WhiteCard>
    </MenuPageContainer>
  );
};

export default LoginPage;
