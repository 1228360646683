import * as _ from "lodash";
import { Component, h, RefObject } from "preact";
import { route } from "preact-router";

import * as api from "../api";
import AuthForm from "../components/AuthForm";
import MenuPageContainer from "../components/MenuPageContainer";
import { User } from "../../../shared/types";
import { GetGroupInviteFromCodeResponse } from "../../../shared/apiTypes";
import WhiteCard from "../components/WhiteCard";
import style from "../styles/InvitePage.module.scss";

interface State {
  groupInviteResponse?: GetGroupInviteFromCodeResponse;
  loginMode: boolean;
  awaitingResponse: boolean;
}

interface Props {
  user?: User;
  inviteCode?: string;
}

class InvitePage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { loginMode: false, awaitingResponse: false };

    this.fetchGroupInvite();
  }

  async fetchGroupInvite() {
    if (this.props.inviteCode === undefined) {
      route("/");
      return;
    }

    const groupInviteResponse = await api.getGroupInvite(this.props.inviteCode);
    if ("group" in groupInviteResponse) {
      document.title = `Join "${groupInviteResponse.group.displayName}" on JigglePix`;
    }

    this.setState({ ...this.state, groupInviteResponse });
  }

  renderCardContents() {
    const { user } = this.props;
    const { groupInviteResponse } = this.state;

    if (groupInviteResponse === undefined) {
      return <div>Loading...</div>;
    }

    switch (groupInviteResponse.type) {
      case "invite-closed":
        return (
          <div>
            This invite link has been closed, please ask the group admin for a
            new link.
          </div>
        );

      case "all-invites-used":
        return (
          <div>
            All invites to this group have been used, please ask the group admin
            to add new invites.
          </div>
        );

      case "already-joined":
        return (
          <div>
            <p>
              You are already a member of "
              {groupInviteResponse.group.displayName}
              ".
            </p>
            <p>
              <a href={`/j/${groupInviteResponse.group.uniqueName}`}>
                Open "{groupInviteResponse.group.displayName}" now!
              </a>
            </p>
          </div>
        );

      case "invite-open": {
        const loginMode = this.state.loginMode;

        return (
          <div>
            <h2>Group Invite</h2>
            {user ? (
              <div>
                <p>
                  Do you want to join the group{" "}
                  <strong>{groupInviteResponse.group.displayName}</strong>?
                </p>
                <button
                  class={style.button}
                  onClick={async () => {
                    await this.joinGroup();
                  }}
                  disabled={this.state.awaitingResponse}
                >
                  JOIN GROUP
                </button>
              </div>
            ) : (
              <div>
                <p>
                  {loginMode ? "Login" : "Sign up"} to join{" "}
                  <strong>{groupInviteResponse.group.displayName}</strong>
                </p>
                <AuthForm
                  mode={loginMode ? "login" : "signUp"}
                  loggedIn={async () => {
                    await this.joinGroup();
                  }}
                  inviteCode={groupInviteResponse.groupInvite.code}
                />
                {loginMode ? (
                  <p>
                    Don't have an account yet?{" "}
                    <a
                      class={style.linkButton}
                      onClick={() => {
                        this.setState({ ...this.state, loginMode: false });
                      }}
                    >
                      Sign up
                    </a>
                  </p>
                ) : (
                  <p>
                    Already have an account?{" "}
                    <a
                      class={style.linkButton}
                      onClick={() => {
                        this.setState({ ...this.state, loginMode: true });
                      }}
                    >
                      Login instead
                    </a>
                  </p>
                )}
              </div>
            )}
          </div>
        );
      }
    }
  }

  async joinGroup() {
    const { groupInviteResponse } = this.state;

    if (groupInviteResponse?.type !== "invite-open") {
      throw Error("Unexpected state");
    }

    this.setState({ ...this.state, awaitingResponse: true });
    await api.useGroupInvite(groupInviteResponse.groupInvite.code);
    // Not using route() here since that doesn't work after loggin in
    window.location.href = `/j/${groupInviteResponse.group.uniqueName}`;
  }

  render() {
    const { user } = this.props;

    return (
      <MenuPageContainer
        user={user}
        background="orange"
        backLink={{ type: "none" }}
        mode="scroll"
      >
        <WhiteCard
          key={this.state.groupInviteResponse ? "got-response" : "waiting"}
        >
          {this.renderCardContents()}
        </WhiteCard>
      </MenuPageContainer>
    );
  }

  componentWillUnmount() {
    document.title = "JigglePix";
  }
}

export default InvitePage;
