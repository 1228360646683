import * as _ from "lodash";
import { Component, h } from "preact";

import { Group, Photo } from "../../../shared/types";
import { photoUrl } from "../../../shared/utils";

import * as api from "../api";
import style from "../styles/ReviewUpcomingPhotos.module.scss";
import * as utils from "../utils";

interface Props {
  group?: string;
}

interface State {
  photos: Omit<Photo, "publishedSlot">[] | undefined;
  group: Group | undefined;
}

class ReviewUpcomingPhotos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      photos: undefined,
      group: undefined,
    };

    this.fetchPhotos();
  }

  async fetchPhotos() {
    if (this.props.group === undefined) {
      throw Error("No group specified");
    }

    const group = await api.getGroup(
      this.props.group,
      utils.daysSinceStartDate()
    );

    const { photos } = await api.upcomingPhotos(group.id);
    this.setState({ ...this.state, group, photos });
  }

  render() {
    if (this.state.photos === undefined || this.state.group === undefined) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        {this.state.photos.map((photo) => (
          <div class={style.photoContainer}>
            <div>{photo.userName}</div>
            <img
              class={style.image}
              src={photoUrl(this.state.group?.id || 0, photo.fileHash)}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default ReviewUpcomingPhotos;
