/**
 * This will show the user's avatar, or, failing that, a colorful circle with the first two
 * letters of their name.
 */
import { FunctionalComponent, h } from "preact";

import { User } from "../../../shared/types";

import * as utils from "../utils";
import style from "../styles/Avatar.module.scss";

interface Props {
  user: User;
  onClick?: () => void;
}

/**
 * Render the photos to the DOM invisibly to force the browser to pre-fetch them so that when we
 * actually show them to the user they won't need to wait.
 */
const Avatar: FunctionalComponent<Props> = ({ user, onClick }) => {
  return (
    <div
      class={utils.cn([style.avatar, onClick ? style.clickable : undefined])}
      onClick={onClick}
    >
      {/* XXX Add actual avatar support */}
      <div class={style.avatarContent}>{user.displayName.substring(0, 2)}</div>
    </div>
  );
};

export default Avatar;
