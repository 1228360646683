// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1LWaQD7I7t8OBI7hkLfVwg\\=\\={position:absolute;top:100%;right:0;background-color:#fff;text-shadow:none;color:#000;list-style:none;padding:20px;font-size:20px;margin:0;text-align:right}._1LWaQD7I7t8OBI7hkLfVwg\\=\\= li{white-space:nowrap;padding:10px}", "",{"version":3,"sources":["webpack://./src/styles/TopBarMenu.module.scss"],"names":[],"mappings":"AACA,6BACE,iBAAA,CACA,QAAA,CACA,OAAA,CACA,qBAAA,CACA,gBAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,cAAA,CACA,QAAA,CACA,gBAAA,CAEA,gCACE,kBAAA,CACA,YAAA","sourcesContent":["\n.dropDown {\n  position: absolute;\n  top: 100%;\n  right: 0;\n  background-color: #fff;\n  text-shadow: none;\n  color: #000;\n  list-style: none;\n  padding: 20px;\n  font-size: 20px;\n  margin: 0;\n  text-align: right;\n\n  li {\n    white-space: nowrap;\n    padding: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropDown": "_1LWaQD7I7t8OBI7hkLfVwg=="
};
export default ___CSS_LOADER_EXPORT___;
