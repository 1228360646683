import _ from "lodash";
import { Component, h } from "preact";

import * as types from "../../../shared/types";
import { photoUrl } from "../../../shared/utils";

import InvisiblePreFetchedPhotos from "./InvisiblePreFetchedPhotos";
import style from "../styles/Slideshow.module.scss";
import * as utils from "../utils";

interface Props {
  photos: types.Photo[];
  groupId: number;
  delayOverlay: boolean;
}

interface State {
  photoIndex: number;
}

/** Time in milliseconds to wait before showing the next photo */
const timeBetweenPhotos = 4000;

class Slideshow extends Component<Props, State> {
  intervalId: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      photoIndex: 0,
    };
  }

  componentWillMount() {
    this.startSlideshowInterval();
  }

  startSlideshowInterval() {
    const photos = this.props.photos;

    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      this.setState({
        ...this.state,
        photoIndex: (this.state.photoIndex + 1) % this.props.photos.length,
      });
    }, timeBetweenPhotos);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { photoIndex } = this.state;
    const { delayOverlay, photos, groupId } = this.props;

    const currentPhoto = photos[photoIndex];

    return (
      <div class={style.container}>
        <div
          key={photoIndex}
          class={style.image}
          style={
            currentPhoto
              ? {
                  backgroundImage: `url(${photoUrl(
                    groupId,
                    currentPhoto.fileHash
                  )})`,
                }
              : {}
          }
        />
        {currentPhoto ? (
          <div
            class={utils.cn([
              style.overlay,
              delayOverlay ? style.delay : undefined,
            ])}
          >
            Photo added by {currentPhoto.userName}
          </div>
        ) : null}
        <InvisiblePreFetchedPhotos
          photoUrls={
            photos.map((photo) => photoUrl(groupId, photo.fileHash)) ?? []
          }
        />
      </div>
    );
  }
}

export default Slideshow;
