import * as _ from "lodash";
import { Component, h } from "preact";

interface Props {
  startTime: number;
  endTime?: number;
  nextButtonTimes: { start: number; end?: number }[];
}

interface State {
  time: number;
}

class Timer extends Component<Props, State> {
  intervalId: any;

  constructor(props: Props) {
    super(props);

    this.state = { time: this.calcTime() };

    if (props.startTime !== undefined) {
      this.startTimer();
    }
  }

  calcTime() {
    return (
      new Date().getTime() -
      this.props.startTime -
      this.props.nextButtonTimes.reduce((total, nextButtonTimes) => {
        if (nextButtonTimes.end) {
          return total + nextButtonTimes.end - nextButtonTimes.start;
        }
        return total;
      }, 0)
    );
  }

  startTimer() {
    this.intervalId = setInterval(() => {
      this.setState({
        time: this.calcTime(),
      });
    }, 200);
  }

  render() {
    return <div>{Math.floor(this.state.time / 1000)}s</div>;
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.startTime === undefined &&
      nextProps.startTime !== undefined
    ) {
      this.startTimer();
    }
  }
}

export default Timer;
