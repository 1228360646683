import { h, render } from "preact";

import "./styles/index.scss";
import App from "./components/App";

const rootElement = document.getElementById("root");

if (rootElement) {
  render(<App />, rootElement);
} else {
  console.error("No root element");
}
