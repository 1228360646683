// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p71WEzzbmjI5TWYaeJBHGw\\=\\={margin:16px 8px;background-color:#fff;border-radius:16px;padding:32px;position:relative}.p71WEzzbmjI5TWYaeJBHGw\\=\\= h2:first-child{margin-top:0}.p71WEzzbmjI5TWYaeJBHGw\\=\\= p:last-child{margin-bottom:0}@media(max-width: 490px){.p71WEzzbmjI5TWYaeJBHGw\\=\\={padding:16px}}.KEibdcM26POjuD2Iaue0SQ\\=\\={max-width:450px;margin:16px auto}@media(max-width: 442px){.KEibdcM26POjuD2Iaue0SQ\\=\\={margin:16px 8px}}", "",{"version":3,"sources":["webpack://./src/styles/WhiteCard.module.scss"],"names":[],"mappings":"AAEA,4BACE,eAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,iBAAA,CAEA,2CACE,YAAA,CAGF,yCACE,eAAA,CAGF,yBAfF,4BAgBI,YAAA,CAAA,CAMJ,4BACE,eAHkB,CAIlB,gBAAA,CAEA,yBAJF,4BAKI,eAAA,CAAA","sourcesContent":["@import \"constants\";\n\n.whiteCard {\n  margin: 2 * $unit $unit;\n  background-color: $color-white;\n  border-radius: 2 * $unit;\n  padding: 4 * $unit;\n  position: relative;\n\n  h2:first-child {\n    margin-top: 0;\n  }\n\n  p:last-child {\n    margin-bottom: 0;\n  }\n\n  @media (max-width: $max-mobile-width) {\n    padding: 2 * $unit;\n  }\n}\n\n$narrow-card-width: 450px;\n\n.narrow {\n  max-width: $narrow-card-width;\n  margin: 2 * $unit auto;\n\n  @media (max-width: calc($narrow-card-width - $unit)) {\n    margin: 2 * $unit $unit;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whiteCard": "p71WEzzbmjI5TWYaeJBHGw==",
	"narrow": "KEibdcM26POjuD2Iaue0SQ=="
};
export default ___CSS_LOADER_EXPORT___;
