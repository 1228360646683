import { FunctionalComponent, h } from "preact";

import { Group, HighScore } from "../../../shared/types";
import * as dateUtils from "../../../shared/dateUtils";

import * as utils from "../utils";
import style from "../styles/GroupSessionItem.module.scss";
import _ from "lodash";

interface Props {
  group: Group;
  coverPhotoUrl?: string;
  day: number;
  groupIndex: number;
  // XXX Add modes and remove onClick handler:
  // "open-group", "start-session", "missed", "open-results"
  mode: "open-group" | "today" | "previous-day";
  highScore?: HighScore & { first: boolean };
}

const Stars: FunctionalComponent<{ stars: number }> = ({ stars }) => (
  <span>
    {_.range(stars).map(() => (
      <span>⭑</span>
    ))}
  </span>
);

const GroupSessionItem: FunctionalComponent<Props> = ({
  coverPhotoUrl,
  day,
  group,
  groupIndex,
  mode,
  highScore,
}) => {
  const missed = mode === "previous-day" && highScore === undefined;
  const date = utils.dayToDate(day);

  return (
    <div>
      <div class={style.groupSessionItem}>
        {mode === "today" || mode === "previous-day" ? (
          <div class={style.date}>
            <div>
              <div class={style.day}>{date.getDate()}</div>
              <div class={style.month}>
                {dateUtils
                  .dateToMonthDisplayString(date)
                  .substring(0, 3)
                  .toUpperCase()}
              </div>
            </div>
          </div>
        ) : null}
        <a
          class={utils.cn([
            style.groupButton,
            missed
              ? style.missed
              : style[
                  `color-${
                    ((groupIndex + utils.daysSinceStartDate(date)) % 4) + 1
                  }`
                ],
          ])}
          href={
            mode === "open-group"
              ? `/j/${group.uniqueName}`
              : missed || highScore !== undefined
              ? `/j/${group.uniqueName}/scores/${day}`
              : mode === "today"
              ? `/j/${group.uniqueName}/puzzle`
              : undefined
          }
        >
          {mode === "open-group" ? (
            <div class={style.groupName}>{group.displayName.toUpperCase()}</div>
          ) : null}
          {coverPhotoUrl ? (
            <img class={style.coverPhoto} src={coverPhotoUrl} />
          ) : (
            <div>No photo</div>
          )}
          <div
            class={style.startNow}
            onClick={() => console.log("clicked button")}
          >
            {highScore ? (
              <div>
                {highScore.first ? <div>1st</div> : null}
                <div>
                  <Stars stars={highScore.perfectCount} />
                </div>
                <div>{highScore.timeTaken}s</div>
              </div>
            ) : mode === "today" ? (
              "START NOW!"
            ) : mode === "previous-day" ? (
              "MISSED"
            ) : (
              "OPEN GROUP"
            )}
          </div>
        </a>
      </div>
    </div>
  );
};

export default GroupSessionItem;
