/**
 * A footer showing 1 or more buttons.
 */
import { FunctionalComponent, h } from "preact";

import * as utils from "../utils";
import style from "../styles/FooterButtons.module.scss";

export interface FooterButtonModel {
  name: string;
  action: () => void;
  type: "primary" | "secondary";
  callout?: string;
}

interface Props {
  buttons?: FooterButtonModel[];
  mode: "full-screen" | "scroll";
  shadow?: boolean;
}

const FooterButtons: FunctionalComponent<Props> = ({
  buttons,
  mode,
  shadow,
  children,
}) => {
  return (
    <div
      class={utils.cn([
        style.footer,
        mode === "full-screen" ? style.fullScreen : style.scroll,
        shadow ? style.shadow : undefined,
      ])}
      id="menu-page-footer"
    >
      <div class={style.footerContent}>
        {(buttons ?? []).map((button) => (
          <div
            class={utils.cn([
              style.button,
              button.type === "primary" ? style.primary : style.secondary,
            ])}
            onClick={button.action}
          >
            {button.name.split(" ").map((word) => (
              <span class={style.word}>{word}</span>
            ))}
            {button.callout ? (
              <div class={style.callout}>{button.callout}</div>
            ) : null}
          </div>
        ))}
        {children}
      </div>
    </div>
  );
};

export default FooterButtons;
