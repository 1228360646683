import * as _ from "lodash";
import { Component, h } from "preact";
import { route } from "preact-router";

import { User } from "../../../shared/types";
import { GetHighScoresResponse } from "../../../shared/apiTypes";

import MenuPageContainer from "../components/MenuPageContainer";
import { FooterButtonModel } from "../components/FooterButtons";
import * as api from "../api";
import * as utils from "../utils";
import Slideshow from "../components/Slideshow";
import style from "../styles/ScoresPage.module.scss";
import HighScoreTicker from "../components/HighScoreTicker";

interface Score {
  stars: number;
  timeTaken: number;
}

interface Props {
  user: User;
  // XXX This is actually mandatory, but it needs to be optional for the router.
  group?: string;
  day?: string;
  matches?: {
    stars: string;
    timeTaken: string;
  };
}

interface State {
  highScoresResponse?: GetHighScoresResponse;
  score?: Score;
}

class ScoresPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let score: Score | undefined;
    if (
      props.matches?.stars !== undefined &&
      props.matches?.timeTaken !== undefined
    ) {
      score = {
        stars: parseInt(props.matches.stars, 10),
        timeTaken: parseInt(props.matches.timeTaken, 10),
      };
    }
    this.state = {
      score,
    };
    window.history.replaceState({}, document.title, window.location.pathname);

    this.fetchScores();
  }

  day() {
    return parseInt(this.props.day!, 10);
  }

  async fetchScores() {
    const { group } = this.props;
    const day = this.day();

    if (group === undefined || isNaN(day)) {
      throw Error("Invalid group or day");
    }

    const highScoresResponse = await api.getHighScores(group, day);
    this.setState({
      ...this.state,
      highScoresResponse,
    });
  }

  isToday(): boolean {
    const day = parseInt(this.props.day!, 10);
    return day === utils.daysSinceStartDate();
  }

  footerButtons(): FooterButtonModel[] | undefined {
    // if (!this.isToday()) {
    //   return undefined;
    // }

    return [
      ...(this.isToday()
        ? [
            {
              name: "Try Again",
              action: () => route(`/j/${this.props.group}/puzzle`),
              type: "primary" as const,
            },
          ]
        : []),
      {
        name: "Add Photos",
        action: () =>
          route(`/j/${this.props.group}/addPhotos?fromDay=${this.props.day}`),
        type: "secondary",
      },
      ...(this.state.highScoresResponse?.admin
        ? [
            {
              name: "Edit Group",
              action: () => {
                console.log("Edit group");
                route(`/j/${this.props.group}/admin?fromDay=${this.props.day}`);
              },
              type: "secondary" as const,
            },
          ]
        : []),
    ];
  }

  userScores() {}

  render() {
    const user = this.props.user;

    if (this.state.highScoresResponse === undefined) {
      return <div>Loading...</div>;
    }

    const { group, highScores, photos } = this.state.highScoresResponse;
    const score = this.state.score;

    console.log("scores render, fullscreen");

    return (
      <div>
        <MenuPageContainer
          user={user}
          background={user === undefined ? "blue" : "white"}
          pageTitle={utils.dayDisplayString(this.day())}
          backLink={{ type: "group", groupUniqueName: group.uniqueName }}
          group={group.uniqueName}
          footerButtons={this.footerButtons()}
          mode="full-screen"
        >
          <div class={style.slideshow}>
            <Slideshow
              photos={photos}
              groupId={group.id}
              delayOverlay={score !== undefined}
            />
            {highScores.length > 0 ? (
              <div
                class={utils.cn([
                  style.tickerContainer,
                  score ? style.waitForUserScore : undefined,
                ])}
              >
                <HighScoreTicker
                  highScores={highScores}
                  user={user}
                  showingUserScoreFirst={score !== undefined}
                />
              </div>
            ) : null}
          </div>
          {score ? (
            <div class={style.wellDoneContainer}>
              <div class={style.wellDoneCard}>
                <p>Well done!</p>
                <p>{_.range(score.stars).map(() => "⭑")}</p>
                <p>{score.timeTaken}s</p>
              </div>
            </div>
          ) : null}
        </MenuPageContainer>
      </div>
    );
  }
}

export default ScoresPage;
