import * as _ from "lodash";
import { Component, h } from "preact";
import { route } from "preact-router";

import { User } from "../../../shared/types";

import * as api from "../api";
import { FooterButtonModel } from "../components/FooterButtons";
import MenuPageContainer from "../components/MenuPageContainer";
import WhiteCard from "../components/WhiteCard";
import style from "../styles/ChangePasswordPage.module.scss";

interface State {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  errorMessage?: string;
}

interface Props {
  user: User;
  group?: string;
  reloadUser?: () => void;
}

class ChangePasswordPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  footerButtons(): FooterButtonModel[] {
    return [
      {
        name: "Change Password",
        action: () => this.submitNewPassword(),
        type: "primary",
      },
    ];
  }

  async submitNewPassword() {
    const { oldPassword, newPassword, confirmPassword } = this.state;

    if (newPassword !== confirmPassword) {
      this.setState({
        ...this.state,
        errorMessage: "Password confirmation doesn't match",
      });
      return;
    }

    if (newPassword.length < 6) {
      this.setState({
        ...this.state,
        errorMessage: "New password must be at least 6 characters long",
      });
      return;
    }

    try {
      await api.updatePassword(oldPassword, newPassword);
    } catch (error: any) {
      console.log("error: ", JSON.stringify(error));
      console.log("error.response: ", error.response);
      this.setState({
        errorMessage: error.response?.data ?? error.message ?? error,
      });
      return;
    }

    this.setState({ ...this.state, errorMessage: undefined });
    alert("Password successfully changed!");
    route(this.props.group ? `/j/${this.props.group}` : "/");
  }

  render() {
    const { user, group } = this.props;
    const { oldPassword, newPassword, confirmPassword } = this.state;

    return (
      <MenuPageContainer
        user={user}
        background={"green"}
        backLink={
          group ? { type: "group", groupUniqueName: group } : { type: "home" }
        }
        group={group}
        mode="scroll"
        footerButtons={this.footerButtons()}
      >
        <WhiteCard>
          <h2>Change Password</h2>
          <div>
            <table class={style.table}>
              <tr>
                <td>Old:</td>
                <td>
                  <input
                    type="password"
                    autoFocus={true}
                    class={style.input}
                    value={oldPassword}
                    placeholder="Type old password"
                    onInput={(event) => {
                      this.setState({
                        oldPassword: event.currentTarget.value,
                        errorMessage: undefined,
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>New:</td>
                <td>
                  <input
                    type="password"
                    class={style.input}
                    value={newPassword}
                    placeholder="Type new password"
                    onInput={(event) => {
                      this.setState({
                        newPassword: event.currentTarget.value,
                        errorMessage: undefined,
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Confirm:</td>
                <td>
                  <input
                    type="password"
                    class={style.input}
                    value={confirmPassword}
                    placeholder="Repeat new password"
                    onInput={(event) => {
                      this.setState({
                        confirmPassword: event.currentTarget.value,
                        errorMessage: undefined,
                      });
                    }}
                  />
                </td>
              </tr>
            </table>
            {this.state.errorMessage && (
              <div class={style.errorMessage}>{this.state.errorMessage}</div>
            )}
          </div>
        </WhiteCard>
      </MenuPageContainer>
    );
  }
}

export default ChangePasswordPage;
