import { Component, h, createRef, RefObject } from "preact";

import style from "../styles/WhiteCard.module.scss";
import * as utils from "../utils";

interface Props {
  narrow?: boolean;
}

class WhiteCard extends Component<Props> {
  cardRef: RefObject<HTMLDivElement> = createRef();

  render() {
    const { children, narrow } = this.props;

    return (
      <div
        class={utils.cn([style.whiteCard, narrow ? style.narrow : undefined])}
        ref={this.cardRef}
      >
        {children}
      </div>
    );
  }

  componentDidMount() {
    this.updatePosition();
    window.addEventListener("resize", this.updatePosition);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePosition);
  }

  updatePosition = () => {
    const header = document.querySelector("#menu-page-header");
    const footer = document.querySelector("#menu-page-footer");

    const headerHeight = header?.getBoundingClientRect().height ?? 0;
    const footerHeight = footer?.getBoundingClientRect().height ?? 0;
    const margin = 16;

    const screenHeight = window.innerHeight;

    const cardHeight =
      this.cardRef.current?.getBoundingClientRect().height ?? 0;

    const cardTop = Math.max(
      0,
      (screenHeight - cardHeight - 2 * margin - footerHeight - headerHeight) / 2
    );

    if (this.cardRef.current) {
      this.cardRef.current.style.marginTop = `${cardTop + margin}px`;
    }
  };
}

export default WhiteCard;
