/**
 * A list of options which you can click to perform different actions.
 */
import { FunctionalComponent, h } from "preact";

import style from "../styles/DropdownMenu.module.scss";

export interface MenuItem {
  name: string;
  action: () => void;
}

interface Props {
  items: MenuItem[];
}

const DropdownMenu: FunctionalComponent<Props> = ({ items }) => {
  return (
    <div class={style.dropdownMenu}>
      {items.map((item) => (
        <div class={style.menuItem} onClick={item.action}>
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default DropdownMenu;
