import { FunctionalComponent, h } from "preact";

import MenuPageContainer from "../components/MenuPageContainer";
import WhiteCard from "../components/WhiteCard";
import AuthForm from "../components/AuthForm";

const EndOfDemoPage: FunctionalComponent<{}> = () => {
  console.log("EndOfDemoPage render");

  return (
    <MenuPageContainer
      background="green"
      backLink={{ type: "none" }}
      mode="scroll"
      // footerButtons={[
      //   {
      //     type: "button",
      //   }
      // ]}
    >
      <WhiteCard narrow={true}>
        <h2>Sign Up For Free To...</h2>
        <p>
          1. Upload <strong>your own</strong> photos
        </p>
        <p>2. Invite friends or family</p>
        <p>3. Play daily and compete with each other</p>
        <AuthForm mode="signUp" loggedIn={() => (window.location.href = "/")} />
      </WhiteCard>
    </MenuPageContainer>
  );
};

export default EndOfDemoPage;
