import * as _ from "lodash";

import { monthDisplayString } from "../../shared/dateUtils";

/** For creating class name strings */
export const cn = (list: (string | undefined)[]) =>
  list.filter((item) => item !== null && item !== undefined).join(" ");

export const getPhotoUrl = (baseUrl: string, size: number): string =>
  baseUrl.startsWith("/assets") ? baseUrl : `${baseUrl}=w${size}-h${size}-c`;

/** Time to wait before auto-scrolling page in ms */
export const autoScrollDelay = 500;

export const scrollPageToSee = (boundingRectBottom: number) => {
  /** Distance in pixels from bottom of screen */
  const targetBottom = 150;
  const y = window.scrollY + boundingRectBottom;
  window.scrollTo({
    left: 0,
    top: y + targetBottom - window.innerHeight,
    behavior: "smooth",
  });
};

const startDate = new Date(2022, 3, 22);

/**
 * Set today's date on initialization instead of using new Date() everywhere so that it doesn't
 * change.
 */
export const todayDate = new Date();

export const dayDisplayString = (day: number) => {
  const date = new Date(startDate);
  date.setDate(date.getDate() + day);
  return `${monthDisplayString[date.getMonth()]} ${date.getDate()}`;
};

export const dayToDate = (day: number) => {
  const date = new Date(startDate);
  date.setDate(date.getDate() + day);
  return date;
};

export const daysSinceStartDate = (date: Date = todayDate) =>
  Math.floor((date.getTime() - startDate.getTime()) / (24 * 3600 * 1000));

export const isTouchDevice = () =>
  "ontouchstart" in window || navigator.maxTouchPoints > 0;
