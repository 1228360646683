// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ctFAgr31OaZeMA8ACt00hQ\\=\\={width:200px}.JJXd3HYmGRe78aFoNt73AA\\=\\={display:inline-block;margin:20px}", "",{"version":3,"sources":["webpack://./src/styles/ReviewUpcomingPhotos.module.scss"],"names":[],"mappings":"AAAA,4BACE,WAAA,CAGF,4BACE,oBAAA,CACA,WAAA","sourcesContent":[".image {\n  width: 200px;\n}\n\n.photoContainer {\n  display: inline-block;\n  margin: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "ctFAgr31OaZeMA8ACt00hQ==",
	"photoContainer": "JJXd3HYmGRe78aFoNt73AA=="
};
export default ___CSS_LOADER_EXPORT___;
