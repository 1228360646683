/** Includes the header and overall page layout for menu (non-session) pages */
import { Component, h } from "preact";
import { route } from "preact-router";

import { User } from "../../../shared/types";

import * as api from "../api";
import Avatar from "../components/Avatar";
import FooterButtons from "../components/FooterButtons";
import { FooterButtonModel } from "../components/FooterButtons";
import * as utils from "../utils";
import style from "../styles/MenuPageContainer.module.scss";
import DropdownMenu from "./DropdownMenu";
import { MenuItem } from "./DropdownMenu";

interface Props {
  user?: User;
  group?: string;
  background: "white" | "blue" | "red" | "green" | "orange" | "no-style";
  pageTitle?: string;
  backLink:
    | { type: "none" }
    | { type: "home" }
    | { type: "group"; groupUniqueName: string; day?: number };
  // XXX Merge these two into a discriminated union:
  footerButtons?: FooterButtonModel[];
  footerContent?: JSX.Element;
  mode: "scroll" | "full-screen";
}

interface State {
  userDropdownState: "collapsed" | "expanded" | "waiting-to-collapse";
  showTopBarShadow: boolean;
}

class MenuPageContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userDropdownState: "collapsed",
      showTopBarShadow: false,
    };
  }

  dropdownMenuItems: MenuItem[] = [
    {
      name: "EDIT PROFILE",
      action: async () => {
        const group = this.props.group;
        route(group ? `/j/${group}/editProfile` : "/editProfile");
      },
    },
    {
      name: "CHANGE PASSWORD",
      action: async () => {
        const group = this.props.group;
        route(group ? `/j/${group}/changePassword` : "/changePassword");
      },
    },
    {
      name: "LOGOUT",
      action: async () => {
        await api.logout();
        window.location.href = "/";
      },
    },
  ];

  renderBackLink() {
    const backLink = this.props.backLink;

    switch (backLink.type) {
      case "none":
        return <div class={style.spacer} />;

      case "home":
        return (
          <a class={style.miniLogo} href="/">
            J
          </a>
        );

      case "group":
        return (
          <a
            class={style.backButton}
            href={`/j/${backLink.groupUniqueName}${
              backLink.day !== undefined ? `/scores/${backLink.day}` : ""
            }`}
          >
            <img src="/assets/backArrow.svg" />
          </a>
        );
    }
  }

  componentDidMount() {
    if (this.props.mode === "scroll") {
      window.addEventListener("scroll", this.onScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.mode === "scroll") {
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  onScroll = () => {
    const showTopBarShadow = window.scrollY > 0;

    if (showTopBarShadow !== this.state.showTopBarShadow) {
      this.setState({ showTopBarShadow });
    }
  };

  showBlurb() {
    return this.props.user === undefined;
  }

  renderHeader(invisible: boolean) {
    const { user, pageTitle, mode } = this.props;
    const showTopBarShadow = this.state.showTopBarShadow;

    return (
      <div
        class={utils.cn([
          style.headerContainer,
          invisible
            ? style.invisible
            : mode === "full-screen"
            ? style.fullScreen
            : style.scroll,
          !invisible && showTopBarShadow ? style.shadow : undefined,
        ])}
        id="menu-page-header"
      >
        <div class={style.headerContent}>
          {this.renderBackLink()}
          {pageTitle ? (
            <span class={style.pageTitle}>{pageTitle}</span>
          ) : this.showBlurb() ? (
            <div class={style.logoAndBlurbContainer}>
              <a class={style.logoContainer} href="/">
                <img
                  src="/assets/jigglePixLogo.svg"
                  class={style.jigglePixLogo}
                />
              </a>
              <p>A daily photo puzzle game to play with friends and family</p>
            </div>
          ) : (
            <a class={style.logoContainer} href="/">
              <img
                src="/assets/jigglePixLogo.svg"
                class={style.jigglePixLogo}
              />
            </a>
          )}
          {user ? (
            <div
              class={style.dropdownContainer}
              onMouseEnter={
                !utils.isTouchDevice()
                  ? () => {
                      this.setState({
                        ...this.state,
                        userDropdownState: "expanded",
                      });
                    }
                  : undefined
              }
              onMouseLeave={
                !utils.isTouchDevice()
                  ? () => {
                      this.setState({
                        ...this.state,
                        userDropdownState: "waiting-to-collapse",
                      });
                      setTimeout(() => {
                        if (
                          this.state.userDropdownState === "waiting-to-collapse"
                        ) {
                          this.setState({
                            ...this.state,
                            userDropdownState: "collapsed",
                          });
                        }
                      }, 1000);
                    }
                  : undefined
              }
            >
              <Avatar
                user={user}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    userDropdownState:
                      this.state.userDropdownState === "collapsed"
                        ? "expanded"
                        : "collapsed",
                  });
                  console.log("onClick");
                }}
              />
              {this.state.userDropdownState === "expanded" ||
              this.state.userDropdownState === "waiting-to-collapse" ? (
                <DropdownMenu items={this.dropdownMenuItems} />
              ) : null}
            </div>
          ) : (
            <div class={style.spacer} />
          )}
        </div>
      </div>
    );
  }

  hasFooter() {
    return this.props.footerButtons || this.props.footerContent;
  }

  render() {
    const { background, children, mode, footerButtons, footerContent } =
      this.props;

    console.log("render menu page container");

    return (
      <div
        class={utils.cn([
          style.pageContainer,
          background !== "no-style" ? style[`color-${background}`] : undefined,
          mode === "full-screen" ? style.fullScreen : style.scroll,
        ])}
      >
        {this.renderHeader(false)}
        {mode === "scroll" ? this.renderHeader(true) : null}
        <div
          class={utils.cn([
            background !== "no-style" ? style.bodyContent : undefined,
            mode === "scroll" && this.hasFooter()
              ? style.paddingForFooter
              : undefined,
          ])}
        >
          {children}
        </div>
        {this.hasFooter() ? (
          <FooterButtons
            buttons={footerButtons}
            mode={mode}
            shadow={background === "white" && mode === "scroll"}
          >
            {footerContent}
          </FooterButtons>
        ) : null}
      </div>
    );
  }
}

export default MenuPageContainer;
